import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheInnerGateHouse() {
    return (
        
    <SideBar title='The Inner Gatehouse' extended={false}>
      <Helmet>
        <title>The Inner Gatehouse | FOBT</title>
        <meta name="description" content="Information and history regarding the inner gatehouse at Buckden Towers" />
        <link rel="canonical" href="/abouttowers/theinnergatehouse" />
      </Helmet>
          <p className="newsinfo1"> The majority of the Inner Gatehouse was constructed by Bishop John Russell (1480 - 1494), who was also responsible for the majority of the extensive
          rebuilding on the site. His arms can be seen over the entrance arch to the Inner Gatehouse (1480) and on its south gable front. <br/><br/>

          After the War, in 1919, The Towers complex was sold to an eccentric historian from Durham, Dr Robert Holmes Edleston. He rebuilt the northern half of the Inner Gatehouse,
          demolished by Marshall, and he was responsible for the inscription "Napoleon III" above the right hand doorway which was to have been the entrance to a small museum of the
          Emperor's relics. <br/><br/>

          Home to the Claretian Missionaries - the current owners of the complex, the Inner Gatehouse also contains four self-catering apartments, each with its own character and style.
          The Friends Refectory on the ground floor at the southern end of the Gatehouse is occasionally used as tea rooms.<hr/></p><br/>

          <h2><Link to="/abouttowers">Return</Link> </h2>
  </SideBar>
    )
}