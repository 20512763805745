import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheOuterGateHouse() {
    return(
        <SideBar title='The Outer Gatehouse' extended={false}>
            <Helmet>
                <title>The Outer Gatehouse | FOBT</title>
                <meta name="description" content="Information and history regarding the outer gatehouse at Buckden Towers" />
                <link rel="canonical" href="/abouttowers/theoutergatehouse" />
            </Helmet>
            <p className="newsinfo1"> The Outer Gateway and some 400 feet of the 14 feet high western perimeter wall remain in their original positions, with but minor modifications to the
                Gatekeeper's Lodge and the substitution of 19th century iron gates for the original hewn oak pair.

            The Visitor Centre and Bookshop in the Outer Gatehouse was opened in July 1993 to help meet development costs at The Towers. For many years it flourished selling books, cards,
            religious items and a whole range of gifts. On 16 March 2003 the Bookshop closed and for some years it served as office accommodation.

            For a while it was run by the Friends as a charity shop but in November 2011 it was rented out as a student centre for the ever-expanding Stageworks organisation.<hr/></p><br/>

            <h2><Link to="/abouttowers"> Return</Link> </h2>
        </SideBar>
    )
}