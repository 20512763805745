import React from 'react'
import { useStore } from '../../../Stores/store';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';

export default observer(function EventTable() {
    const { generalStore} = useStore();
    const { events, setSelectedEvent, setEventConfirmationModalStatus } = generalStore;

    let rows = [...events];

    const columns: GridColDef[] = React.useMemo(() => [
        { field: 'title', headerName: 'Title', flex: 1},
        { field: 'date', headerName: 'Date', flex:1 },
        {
            field: "",
            headerName: "Actions",
            disableClickEventBubbling: true,
            renderCell: (params) => {
              const onClickEdit = () => {
                setSelectedEvent(params.id as number);
              };

              const onClickDelete = () => {
                setEventConfirmationModalStatus(true, params.id as number);
              }
        
              return <div><Button onClick={onClickEdit}><Edit /></Button><Button onClick={onClickDelete}><Delete /></Button></div>;
            },
            flex: 1
        }
        ], []);

    return (
        <DataGrid rows={rows} columns={columns} autoHeight={true} />
    )
});