import { Editor } from '@tinymce/tinymce-react';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react'
import { ContentType } from '../../../Enumerators/ContentType';
import { News } from '../../../Models/News';
import { useStore } from '../../../Stores/store';

export default observer(function NewsForm() {
    const { generalStore } = useStore();
    const { selectedNews, addUpdateNews } = generalStore; 
    const apiKey: string | undefined = process.env.REACT_APP_TINY_MCE_KEY;
  
    let editNews: News | undefined = undefined;
    if(selectedNews !== undefined) editNews = {...selectedNews}
    const formVals : News = editNews ?? {
      id: 0,
      title: "",
      content: "",
      type: ContentType.news
    }
  
    const handleSubmit = (values : any) => {
      addUpdateNews(values);
    }
  
    return (
      <Formik
      initialValues={formVals}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
      >
      {({handleSubmit, handleChange, values}) => (
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">News Title</label>
          <br />
          <input type="text" name="title" id="title" value={values.title} onChange={handleChange} />
          <br/>
          <label htmlFor="content">Content</label>
          <Editor
            apiKey={apiKey}
            initialValue="<p>News...</p>"
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist autolink lists link image",
                "charmap print preview anchor help",
                "searchreplace visualblocks code",
                "insertdatetime media table paste wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
            }}
            onChange={handleChange}
            onEditorChange={(e) => {
              handleChange({target: {name: "content", value: e}})
            }}
            value={values.content}
          />
          <br />
          <input type="hidden" id="id" name="id" value={values.id} />
          <br />
          <input type="submit" value="Submit News" />
        </form>
      )} 
    </Formik>
    );
});