import React from 'react'
import { useStore } from '../../../Stores/store';
import { Formik } from 'formik';
import { DocumentFormValues } from './DocumentFormValues';
import DocumentUploadWidget from './DocumentUploadWidget';
import { observer } from 'mobx-react-lite';
import './DocumentForm.scss';

export default observer(function DocumentForm(){
    const { managementStore, categoryStore } = useStore();
    const { updateDocument, selectedDocument, uploadDocument, documentToBeUploaded, documentToBeUploadedFileName } = managementStore;
    const { categories } = categoryStore;

    const formVals : DocumentFormValues = selectedDocument ?? {
        id: 0,
        file: "",
        title: "",
        categoryId: ""
      }

    const handleSubmit = (values : any) => {
        if((values.id === undefined || values.id === 0) && documentToBeUploaded !== undefined){
            uploadDocument(documentToBeUploaded, values)
        }
        else {
            updateDocument(values)
        }
    }

    return (
        <div>
            <DocumentUploadWidget />
            <Formik
            initialValues={formVals}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
            >
            {({handleSubmit, handleChange, values}) => (
            <form onSubmit={handleSubmit}>
                <div className="fieldsContainer">

                    <label htmlFor="file" className={selectedDocument !== undefined ? "hideField" : ""}>File</label>
                    <input type="text" name="file" id="file" className={selectedDocument !== undefined ? "hideField" : ""} value={documentToBeUploaded !== undefined ? `${documentToBeUploadedFileName} - ready for upload - give it a title` : ""} disabled={true} onChange={handleChange} />
                    <br />
                    

                    <label htmlFor="title">Title</label>
                    <input type="text" name="title" id="title" value={values.title} onChange={handleChange} />
                    <br/>
                    <label htmlFor="categoryId">Category</label>
                    <select name="categoryId" id="categoryId" value={values.categoryId} onChange={handleChange}>
                        <option value="">None</option>
                        {categories.map(category => {
                            return <option key={`category_${category.id}`} value={category.id}>{category.name}</option>
                        })}
                    </select>
                    <input type="hidden" id="id" name="id" value={values.id} />
                    <br />
                    <input type="submit" value="Upload" />
                </div>
            </form>
            )} 
        </Formik>
      </div>
    )
});