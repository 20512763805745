import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import Picture from "../Models/Picture";
import { store } from './store';

interface hasShownPosterCacheItem {
    value : boolean,
    expiry : Date,
    id : string
}

export default class PosterStore {
    currentPoster : Picture | null = null;
    isPosterActive : boolean = false;
    hasShownPoster : boolean = false;
    hasFetchedPoster : boolean = false;

    constructor(){
        makeAutoObservable(this);
        const cachedItem : hasShownPosterCacheItem | undefined = this.fetchHasPosterBeenShowFromCache();
        if(cachedItem){
            const DateToCheck = new Date();
            if(cachedItem.expiry < DateToCheck){
                window.localStorage.removeItem('hasShownPoster');
                this.hasShownPoster = false;
            }
            else if (cachedItem.id === this.currentPoster?.id){
                window.localStorage.removeItem('hasShownPoster');
                this.hasShownPoster = false;
            }
            else {
                this.hasShownPoster = cachedItem.value;
            }
        }
    }

    getCurrentPoster = async(displayResponse : boolean) => {
        try {
            const response = await agent.poster.getActivePoster();
            if(response){
                if(displayResponse){
                    this.setIsPosterActive(true);
                }
                
                this.setCurrentPoster(response);
                this.hasFetchedPoster = true;
            }
        }
        catch (ex) {
        }
    }

    closePoster = (id : string) => {
        this.isPosterActive = false;
        this.hasShownPoster = true;
        this.addPosterHasBeenShownToCache(id);

    }

    fetchHasPosterBeenShowFromCache = () => {
        const item : string | null = window.localStorage.getItem('hasShownPoster');
        if(item){
            return JSON.parse(item) as hasShownPosterCacheItem;
        }
        return undefined;
    }

    addPosterHasBeenShownToCache = (id : string) => {
        const item : hasShownPosterCacheItem | undefined = this.fetchHasPosterBeenShowFromCache();
        if(item){
            window.localStorage.removeItem('hasShownPoster');
        }

        let todaysDate = new Date();
        let tomorrowsDate = new Date();
        tomorrowsDate.setDate(todaysDate.getDate() + 1);
        const newItem : hasShownPosterCacheItem = {
            value: true,
            expiry: tomorrowsDate,
            id: id
        }

        if(newItem){
            window.localStorage.setItem('hasShownPoster', JSON.stringify(newItem));
        }
    }

    deactivatePoster = async (pictureId : string) => {
        if(pictureId){
            try {
                var result : Picture = await agent.poster.deactivatePoster(pictureId);
                if(result){
                    runInAction(() => {
                        this.currentPoster = null;
                        let updatedPoster = store.managementStore.galleryPictures.find(x => x.id === pictureId);
                        if(updatedPoster){
                            updatedPoster.isActive = true;
                            store.managementStore.galleryPictures = [...store.managementStore.galleryPictures.filter((x)=> {return x.id !== pictureId}), updatedPoster];
                        }
                        
                        store.managementStore.closePosterPreview();
                    })
                }
            }
            catch (ex){
                console.log(ex);
            }
        }
    }


    setNewCurrentPoster = async (pictureId: string) => {
        if(pictureId){
            try {
                var result : Picture = await agent.poster.setCurrentPoster(pictureId);
                if(result){
                    this.setCurrentPoster(result);
                    store.managementStore.closePosterPreview();
                }
            }
            catch (ex){
                console.log(ex);
            }
            
        }
    }

    setCurrentPoster = (poster : Picture) => {
        this.currentPoster = poster;
    }  
    
    setIsPosterActive = (trueOrFalse : boolean) => {
        this.isPosterActive = trueOrFalse;
    }

    previewNewPoster = (id : string) => {
        let picture : Picture | undefined = store.managementStore.galleryPictures.find(picture => picture.id === id);
        if(picture){
     
        }
    }
}
