import React from 'react';
import { useStore } from '../../../../Stores/store';
import './Picture.scss';

interface Props {
    url: string,
    alt: string,
    id: string,
    isActive: boolean
}

export default function PictureTile({url, alt, id, isActive}: Props){
    const { managementStore } = useStore();
    const { setSelectedPictureId } = managementStore;

    return(
        <div key={id} id={`${id}`} onClick={() => {window.scrollTo(0, 0); setSelectedPictureId(id)}} className="pictureTile" style={isActive ? {backgroundColor: 'green'} : {}}>
            <img src={url} alt={alt} />
        </div>
    );
}