import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { AiFillMail } from 'react-icons/ai';
import ReviewGallery from './ReviewGallery/ReviewGallery';
import SideBar from '../../Components/SideBar/SideBar';
import './Tours.scss';
import { Helmet } from 'react-helmet';

export default function Tours() {

    return (
        <SideBar extended={true} title='Guided Tours of Buckden Towers'>
            <Helmet>
                <title>Tours | FOBT</title>
                <meta name="description" content="Information on the tours of Buckden Towers" />
                <link rel="canonical" href="/tours" />
            </Helmet>

            <p>Buckden Towers is a Grade 1 Scheduled National Monument and has a rich and fascinating history spanning over 900 years.
                Many notable people have visited Buckden Towers during the days of its greatness. Probably the most important Royal visitor was Katherine of Aragon.
            </p>

            <p>Guided tours of the estate are arranged, by prior appointment, through the Friends of Buckden Towers. Contacts are: </p>
            <h3>Contact us</h3>
            <ul id="toursContactList">
                <li className="toursContactList_Item"><BsFillTelephoneFill className='toursContactList_Item_Icon' /><a href="tel:07762919821" className='toursContactList_Item_ContactItem'>07762 919821</a></li>
                <li className="toursContactList_Item"><AiFillMail className='toursContactList_Item_Icon' /><a href="mailto:admin@friendsofbuckdentowers.co.uk" className='toursContactList_Item_ContactItem' >admin@friendsofbuckdentowers.co.uk</a></li>
            </ul>
            
            <p>
                Tours last approximately 1 hour, cost £5.00 per person, and refreshments can be arranged for groups of 5 or more at extra cost. Small and larger groups can be catered for subject to reasonable notice.
            </p>

            <p>
                Our guides are all volunteers who give freely of their time and knowledge. People find the tours of great interest, and some recent feedback is shown below.
            </p>

            <p>
                To book a tour contact by phone or email. Details above.

                Please give at least 5 days notice and please state the numbers of guests involved
            </p>
            <p>
                Tours operate from the 1st March to the 31st October.
            </p>

            <h3 className='centered'>Feedback</h3>
            <ReviewGallery />
        </SideBar>
    );
}