import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useStore } from '../../Stores/store';
import './NavBar.scss';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import { NavBarLink } from './NavBarLink';

export default observer(function NavBar(){
    const { posterStore, generalStore } = useStore();
    const { getCurrentPoster, hasFetchedPoster } = posterStore;
    const { isMobile, setIsMobile } = generalStore;
    
    const links : NavBarLink[] = [
        { title: "Home", href:"/" },
        { title: "About the Towers", href: "/aboutTowers" },
        { title: "About FOBT", href: "/aboutfobt" },
        { title: "History", href: "/history" },
        { title: "News and Events", href: "/newsandevents" },
        { title: "Documents", href: "/documents"},
        { title: "Membership", href: "/membership" },
        { title: "Tours", href: "/tours"},
        { title: "Gallery", href: "/gallery" },
        { title: "Links", href: "/links" }
    ]

    useEffect(() => {
        if(!hasFetchedPoster){
            getCurrentPoster(false);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth); // Adjust the width threshold as needed
        };
    
        handleResize(); // Check the initial screen width
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        isMobile ? <MobileNavBar links={links} /> : <DesktopNavBar links={links} />
    )
});