import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function AboutTowers(){
    return (
        <SideBar title='About the Towers' extended={false}>
            <Helmet>
                <title>About Towers - FOBT</title>
                <meta name="description" content="Historical information about the various elements of Buckden Towers" />
                <link rel="canonical" href="/abouttowers" />
            </Helmet>
                <h2 className="infoLink"><Link to="/abouttowers/thegreattower">The Great Tower</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/theinnergatehouse">The Inner Gatehouse</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/theoutergatehouse">The Outer Gatehouse</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/thevictorianhouse">The Victorian House</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/thegardenandgrounds">The Garden and Grounds</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/thequeenkatherineknotgarden">The Queen Katherine Knot Garden</Link></h2><br/>
                <h2 className="infoLink"><Link to="/abouttowers/sthugheschurchandchapels">St. Hugh's Church and Chapels</Link></h2>
        </SideBar>
    )
}