import React from 'react'
import './GalleryImage.scss';

interface Props {
    imagePath: string;
    hoverText: string;
    altText?: string;
    titleText?: string;
}

export default function GalleryImage({imagePath, hoverText, altText, titleText}: Props) {
    return (
        <figure className="rig-cell">
            <img className="rig-img" src={`./Images/Gallery/GalleryImages/${imagePath}`} alt={altText ?? ""} title={titleText ?? ""}/>
            <span className="rig-overlay"></span>
            <figcaption className="rig-text">
                {hoverText}
            </figcaption>
        </figure>
    )
}