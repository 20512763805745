import { Delete, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react'
import { useStore } from '../../../Stores/store';
import { observer } from 'mobx-react-lite';


export default observer(function DocumentTable(){
    const { managementStore} = useStore();
    const { documents, setSelectedDocumentId, deleteDocument } = managementStore;

    let rows = [...documents];

    const columns: GridColDef[] = React.useMemo(() => [
        { field: 'id', headerName: '', hide: true},
        { field: 'url', headerName: '', hide: true},
        { field: 'title', headerName: 'Title', flex: 1,  renderCell: (params) => (
          <a href={params.row.url} target="_blank" rel="noreferrer">
            {params.row.title}
          </a>
        )},
        {
            field: "",
            headerName: "Actions",
            disableClickEventBubbling: true,
            renderCell: (params) => {
              const onClickEdit = () => {                
                setSelectedDocumentId(params.row.documentId.toString());
              };

              const onClickDelete = () => {
                deleteDocument(params.row.documentId.toString(), params.row.title);
              }
        
              return <div>
                <Button onClick={onClickEdit}><Edit /></Button>
                <Button onClick={onClickDelete}><Delete /></Button>
              </div>;
            },
            flex: 1
        }
        ], [documents]);

    return (
        <DataGrid rows={rows} columns={columns} autoHeight={true} />
    )
});