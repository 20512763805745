import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { useStore } from '../../Stores/store';
import Event from './Event/Event';
import News from './News/News';
import './NewsAndEvents.scss'

export default observer(function NewsAndEvents() {
    const { generalStore } = useStore();
    const { getNewsAndEvents, events, news, loading, haveNewsAndEvents, updateHaveNewsAndEvents, setIsManagement } = generalStore

    useEffect(() => {
        setIsManagement(false);
        if(!haveNewsAndEvents){
            getNewsAndEvents();
            updateHaveNewsAndEvents(true);
        } 
    }, [haveNewsAndEvents]);

    return (
        <main id="newsAndEvents">
            <Helmet>
                <title>News and Events | FOBT</title>
                <meta name="description" content="Information and news about the current and future programme of FOBT’s fund raising activities and events" />
                <link rel="canonical" href="/newsandevents" />
            </Helmet>
            <div className="infoContainer">
                <h1>News</h1>
                { loading ? <LoadingSpinner /> : news.map((news, index) => {
                   return <News key={`news_${index}`} title={news.title} content={news.content} />
                })}
            </div>
            <div></div>
            <div className="infoContainer">
                <h1>Events</h1>
                { 
                loading ? 
                <LoadingSpinner /> : events.map((event, index) => {
                    return <Event key={`event_${index}`} title={event.title} description={event.content} date={event.date} price={event.price}/>
                    })
                }
            </div>
        </main>
    )
});