import React from 'react';
import './ReviewItem.scss';

interface props {
    title: string,
    description: string,
}

export default function ReviewItem( { title, description }: props) {
    return (
        <div className="reviewItem_container">
            <h4 className="reviewItem_Title">"{title}"</h4>
            <p className="reviewItem_Description">{description}</p>
        </div>
    );
}
