import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import "./Membership.scss";
import SideBar from '../../Components/SideBar/SideBar';

export default function Membership(){
    return (
        <SideBar title='Membership' extended={false}>
            <Helmet>
                <title>Membership | FOBT</title>
                <meta name="description" content="Membership resources for the Friends of Buckden Towers" />
                <link rel="canonical" href="/membership" />
            </Helmet>
            <p className="newsinfo1">The Friends of Buckden Towers is a non-religious registered charitable trust which exists to raise funds 
                in support of the conservation of the Grade 1 Scheduled Historic Monument Buildings of Buckden Towers. 
                New members are always welcome. More information can be seen on the <NavLink to="/aboutfobt">“About FOBT”</NavLink> page.

                Printable copies of the Membership forms can be opened from the links below
            </p>
            <div className="formLinksContainer">
                <h3 id="formsHeader" className="subtitles">Forms</h3>
                <ul className="MembershipLinks">
                    <li><a href="https://res.cloudinary.com/friends-of-buckden-towers/image/upload/v1689496335/MEMBERSHIP_FORM_2023_24_ubfsbg.pdf" target="_blank" rel="noreferrer">Membership Form 2023/24</a></li>
                    <li><a href="https://res.cloudinary.com/friends-of-buckden-towers/image/upload/v1689496335/GIFT_AID_FORM_2024_x8cddq.pdf" target="_blank" rel="noreferrer">Gift Aid Declaration</a></li>
                    <li><a href="https://res.cloudinary.com/friends-of-buckden-towers/image/upload/v1689496335/Standing_Order_Mandate_alajzd.pdf" target="_blank" rel="noreferrer">Standing Order Mandate</a></li>
                </ul>
            </div>
        </SideBar>
    )
}