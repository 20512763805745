  import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useStore } from '../../Stores/store'
import { AiOutlineUpload } from 'react-icons/ai' 

export default function PhotoUploadWidget(){
    const { managementStore } = useStore();
    const { uploadPicture } = managementStore;
    
    const dzStyles = {
        border: 'dashed 3px #eee',
        borderColor: '#eee',
        borderRadius: '5px',
        paddingTop: '30px',
        textAlign: 'center' as 'center',
        height: 100,
        width:'80%',
        margin: '0px auto'
    }

    const dzActive = {
        borderColour: 'green'
    }

    const onDrop = useCallback((acceptedFiles : File[]) => {
        // Do something with the files
        if(acceptedFiles && acceptedFiles.length > 0){
            uploadPicture(acceptedFiles[0]);
        }
      }, []);
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    
      return (
        <div {...getRootProps()} style={isDragActive ? {...dzStyles, ...dzActive} : dzStyles }>
          <input {...getInputProps()} />
          <AiOutlineUpload style={{fontSize:'2rem'}} /> <h4>Upload</h4>
        </div>
      )
    
}