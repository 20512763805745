import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheGardenAndGround() {
    return (
    <SideBar title='The Garden and Grounds' extended={false}>
        <Helmet>
            <title>The Garden and Grounds | FOBT</title>
            <meta name="description" content="Information regarding the garden and the grounds at the Buckden Towers" />
            <link rel="canonical" href="/abouttowers/thegardenandgrounds" />
        </Helmet>
        <p className="newsinfo1"> The field beyond the car park, fenced off from the rest of the estate in 1993, is leased at certain times of the year to a local farmer for grazing his
            sheep and it has also been the site for traction engine and caravan rallies. <br/><br/>


        Leaving the car park by the footpath starting in the north-west corner and following the 17th century raised footpath round the perimeter of the estate, you will come eventually
        to the 17th century fishpond, originally four separate ponds, which in the early 1990s was dredged and restocked with over 2,000 bream by the National Rivers Authority,
        who also cleared fallen and dangerous trees from the lake and west bank. Following the walk, the house, church and tower will come back into view as do the orchard or the limes
        walk. The recreation of the 16th century orchard was completed by 1993 thanks to the generous donation of trees and hard work by the Buckden Gardeners’ Association. Mulberry,
        medlar, quince, apricot and peach trees, all of which would have existed in the Tudor period, were planted. At roughly the same time, the lime walk comprising an avenue of
        pleached limes was also planted.<hr/></p><br/>

        <h2><Link to="/abouttowers"> Return</Link></h2>
    </SideBar>
    )
}