import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function History() {
    return (
        <SideBar title='History' extended={false}>
            <Helmet>
                <title>History | FOBT</title>
                <meta name="description" content="Information concerning the origins, ownership, and important residents and visitors to Buckden Towers" />
                <link rel="canonical" href="/history" />
            </Helmet>
            <div className="infoSideBarContainer">
                <h2 className="infoLink"><Link to="/history/briefhistory">A Brief History of the Towers</Link></h2><br/>
                <h2 className="infoLink"><Link to="/history/guide">A Guide to Buckden Palace</Link></h2><br/>
                <h2 className="infoLink"><Link to="/history/katherine">Katherine of Aragon</Link></h2><br/>
                <h2 className="infoLink"><Link to="/history/parish">History of the Parish of St Hugh of Lincoln</Link></h2><br/>
                <h2 className="infoLink"><Link to="/history/claretians">The Claretians</Link></h2><br/>
            </div>
        </SideBar>
    )
}