import React from 'react'
import parser from 'html-react-parser'

interface Props {
    title: string,
    date?: string | null,
    description: string,
    price: number,
    id?: number,
}

export default function Event({id, title, date, description, price}: Props) {
    return (
        <article key={id}>
            <h2 style={{marginBottom:"5px"}} key={`title_${id?.toString()}`}>{title}</h2>
            <div style={{padding:"2% 5%"}} key={`content_${id}`}>
                {date ? <p style={{textAlign:"center"}} key={`date_${id?.toString()}`}>{generateDate(date)}</p> : null}
                {parser(description)}
                {  
                    price > 0 ? <p>Price: £{price}</p> : null
                }
            </div>
            <hr />
        </article>
    )
}

const generateDate = (dateString : string) => {
    if (!dateString) {
        return "";
    } 

    return new Date(dateString).toLocaleString('en-gb')
}