import React from 'react'
import './Home.scss'
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
    <main id="main">    
        <Helmet>
            <title>Home | FOBT</title>
            <meta name="description" content="Friends of Buckden Towers is a Charitable Trust supporting the owners of Buckden Towers in the maintenance and conservation of this grade1 listed National Monument" />
            <link rel="canonical" href="/" />
        </Helmet>
        <h1 id ="HomeTitle">Friends of <br/> Buckden Towers</h1>
        <h2 id ="moreinfo">MORE INFO BELOW</h2>
    </main>
    )
}