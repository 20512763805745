import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function Guide(){
    return (
        <SideBar title='A Guide to Buckden Towers' extended={true}>
            <Helmet>
                <title>Guide | FOBT</title>
                <meta name="description" content="A outline on the history of Buckden Towers" />
                <link rel="canonical" href="/history/guide" />
            </Helmet>
            <p className="newsinfo1">
                BUCKDEN PALACE <br/><br/>

                At the time of the Domesday survey, Buckden belonged to the Bishop of Lincoln, whose great diocese stretched from the Humber to the Thames until the foundation of the See of Ely (1108),
                Peterborough (1541), Oxford (1542), Southwell (1884), and at least from the time of St. Hugh (1199) it was one of the episcopal residences. In 1291 the house and its appendages were totally
                destroyed by fire. Records exist in which it is written that in 1291 a Thomas de Beyville received royal license to "draw oaks from Our forest of Weybrigge" — Weybridge, 3 miles north of
                Buckden — "for rebylding the bishoppes Mannoir of Buckedene, lately burned by mis¬adventure". The work of Restoration and improvement was subsequently carried out by Bishop John Russell
                (1480-94), Archbishop Rotherham and Bishops John Williams (1625-42), Sanderson (1660), Pretyman (1787-1820) and Kaye. Bishop Kaye was the last bishop to reside here, and on leaving in 1838
                the building was dismantled and a great part pulled down. In 1870 the estate was sold to Mr. James Marshall, a member of the Marshall and Snelgrove business firm of London, who built a large
                house near the old buildings, pulled down the 13th century great chamber and the chapel and did away with the moat bridge. From 1919 onwards, Dr. Robert Holmes Edleston of Gainford, then owner
                of the estate, was the author of much spasmodic excavation and reconstruction mostly with old material. The foundation stone of the partly reconstructed chapel was laid in 1921. In 1956 the
                Claretian Missionaries — a Roman Catholic Missionary Congregation — took possession of the estate and at once began to attend to the badly neglected buildings. By April 1956 the Marshall
                house was ready to receive the community of priests and brothers, and by Septem¬ber 31 students were in residence. The school closed in July 1965 and since then the greatly reduced community
                    continues to render service to the Roman Catholics of Buckden, take care of the- property and conduct Retreats for lay people. <br/><br/>


                THE OUTER GATEWAY and some 400 feet of the 14 feet high western perimeter wall remain in almost original condition, with but minor modifications to the Gatekeeper's Lodge and the substitution
                of 19th century iron gates for the original hewn oak pair. <br/><br/>

                THE INNER GATEHOUSE range was built in 1494. This range, of which the original Entrance Arch and south wing remain intact, is of red brick, diapered in parts with black brick ; the leaded
                windows and embattled parapets are dressed with stone. The Arch forms the base of a two-storied rectangular turret which contains a spiral stairway rising to an additional storey in a small
                turret at the north-east angle. A small apartment on the south side of the arch was used as an almonry, the window still existing through which needy travellers received their dole. Above the
                western entrance the arms of Bishop Russell (1480-94) are emblazoned in the brick¬work and also appear similarly on the gable of the South Wing. <br/><br/>

                THE GREAT TOWER'S foundations were laid about the year 1479 by Bishop Rotherham (1472-80), but this work ceased upon his death in 1480 and was not recommenced by his successor, Bishop Russell,
                until 1491. The lowest storey resting upon the vaulted arches of the great cellar, formed a large dining hall, the large apartment immediately above it being called the King's Lodging. The Great
                Tower also contained ten other rooms. The restoration of the tower was undertaken by the Claretian Missionaries in 1957 and used as dormitories and play room for the school. <br/><br/>

                THE CURTAIN WALL runs from the south-east corner of the South Wing of the Inner Gatehouse to the south-west turret of the Great Tower, with which it is contemporary and is, similarly, of red
                brick with stone facings, having an embattled parapet and a parapet walk. The battlements are each pierced by a vertical slot for defence and similar slots also exist in the lower wall beneath
                the parapet walk. <br/><br/>

                THE MOAT was filled in at various times between 1669 and 1871, and traces of it may still be found here and there in the park. The 13th century Fishponds now form one long stretch of water along
                almost all of the eastern boundary, forming the sheet of water spoken of locally as the Canal. <br/><br/>

                THE KING'S OAK. Round the buildings and curtilage on the north and east sides is the Little Park of about 9 acres — a well-wooded enclosure containing several trees of great size, the chief
                being a giant oak, considered by experts to be 800-1,000 years old. The girth of its trunk at 5 feet from the ground is 19ft. 9 in., and the spread of its branches in the longest direction is
                121 feet, and the shortest 103 feet. An unusual feature in trees of this age is its excellent state of preservation. No signs of decay anywhere in its trunk or branches. <br/><br/>

                EXCAVATIONS AND RESTORATIONS <br/><br/>

                Dr. Robert Holmes Edleston conducted a series of laborious excavations around the years 1919-1929 with the object of discovering the foundations of the ancient buildings, which had been
                entirely covered. He met with a great deal of success, and in some cases, where the nature of the founda¬tions seemed to warrant it, made a few restorations, sufficient to convey some idea,
                partially at least, of the buildings as they were several centuries ago. In these restorations he used wherever possible, old material dug up during the excavations. Much other material,
                including stone and oak beams, came from Stanwick Hall, Yorkshire, and Hornby Castle. All this restoration work was left unfinished and became completely neglected. With the passing of the
                years most of the stones crumbled and became useless. <br/><br/>

                THE BISHOP'S CHAPEL. Most of the bricks in the south wall were dug up in the ruins. The other old bricks in the rebuilt chapel are from Stanwick. The Claretian Missionaries completed the
                brickwork and roofed the building shortly after their arrival. The restored chapel is now used as a Sacristy for the modern chapel. <br/><br/>

                THE GREAT HALL. The foundation walls indicate a building corresponding very closely in dimensions with the Great Hall described by the Parliamentary Commissioners shortly before it was pulled
                down. This was a building with nave and aisles running north and south at right angles to the Chapel and the Great Chamber, and abutting at its southern end upon what was probably a small centre
                cloister, afterwards covered in, and forming the main portion of the Bishop's residence. Nothing now remains but a part of the foundation wall. <br/><br/>

                NORTH WING OF INNER GATEHOUSE. The stone walling and brickwork, doorway and mullioned windows are from Stanwick Hall, Yorkshire, pulled down in 1923, with the following exceptions :
                the four-light window on the ground floor in the east wall is from Trinity Church Schools, Stockton-on-Tees : the two smallest windows on the same side and the three tracery windows above, are
                from Sedbury Hall, Yorks. These are 15th century windows, corresponding with the tracery windows in the old part of the Gatehouse adjoining. <br/><br/>

                Napoleon III had no connection with Buckden Palace. <br/><br/>

                The work of restoration of the north wing was completed by the Claretian Missionaries. <br/><br/>

                ANCIENT COINS. The only ancient coins found during the excavations are a Roman one of Tetricus I, under the Great Tower, two silver pieces of Edward III, on the moat wall, and a French
                jetton of the 15th century. Some Roman tiling was found near the Great Hall and part of a human jaw and some more tiling in the Great Tower. <br/><br/>

                THE MODERN CHURCH is dedicated to St. Hugh of Lincoln. Built by Wrycroft Ltd., of St. Neots, and designed by Buries and Newton (London), the church was dedicated by the Rt. Rev.
                Thomas Leo Parker, M.A., Bishop of Northampton, on October 4th, 1959. <br/><br/>

                NOTES OF INTEREST <br/><br/>

                The body of St. Hugh rested here for one night while being carried from London to Lincoln Cathedral for burial. <br/><br/>

                Bishop Robert Grossetete who enlarged the Great Hall and was often in residence, died here in 1253. <br/><br/>

                Katherine of Aragon was "lodged" here from July 1533 to May 1534. There was a window in her apartment (the Great Chamber) which looked into the Bishop's chapel. At this window she spent many
                hours in prayer and shed many tears. It was at Buckden Palace where two of her chaplains — Abell and Barker — were arrested and committed first to the porter's ward in the Gatehouse and later to
                the Tower of London. <br/><br/>

                Henry and Charles Brandon, sons of Henry VIII's favourite, Charles Brandon, Duke of Suffolk, at the order of their mother, the Duchess of Suffolk, were brought to Buckden Palace from Cambridge
                to avoid the sweating plague, but died of it in forty-eight hours of arrival. They died July 16th, 1551, and are buried in the old churchyard close by. <br/><br/>

                Robert Sanderson became Bishop of Lincoln at the Restoration. He drafted the Preface for the 1662 Prayer Book. His episcopate lasted but three years. He died at Buckden Palace on June 20th,
                1663, and was buried in the chancel of the parish church. <br/><br/>

                Thomas Barlow was Consecrated Bishop of Lincoln in £676. It is said he visited his cathedral city but once and came to live at Buckden Palace until his death in 1691. His tomb is in the
                chancel of the parish church. From his constant residence at Buckden Palace he was usually known as the Bishop of Buckden. <br/><br/>

                Richard Neile entertained King James I here, as appears from some notes made by his brother, William Neile, in an almanack now in Durham Cathedral Library. <br/><br/>

                "1614. February 18th. Brother Richard translated to Lincoln.
                October 10th. Brother and his family to Bugden.
                1616.  October 17th. His Majesty dyned at my brother's house at Bugden." <br/><br/>

                In1630, Bishop Williams was informed against and heavily fined for allowing a play — apparently "Midsummer Night's Dream" — to be performed in the Palace, beginning about 10 p.m. on a Sunday
                and lasting till 2 or 3 a.m., while one Mr. Wilson "because he was especially plotter and contriver of this business and did in such a brutish manner act the same with an Asse's head, therefore
                he shall uppon Tuesday next from 6 in the morning, till 6 at night, sitt at the Porter's Lodge at my Lord Bishopp's house with his feete in the stockes and attyred with his Asse head and a
                bottle of hay .sett before him and this superscription on his breast: <br/><br/>

                "Good people I have played the beast And brought ill things to passe, 1 was a man, but thus have made Myselfe a silly Asse."
            </p>
        <hr/><br/>
        <h2><Link to="/history"> Return</Link> </h2>
    </SideBar>
    )
}