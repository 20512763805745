import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DocumentDTO } from '../Models/DocumentDTO';
import { Event } from '../Models/Event';
import LoginDTO from '../Models/LoginDTO';
import { News } from '../Models/News';
import Picture from '../Models/Picture';
import RegisterDTO from '../Models/RegisterDTO';
import UserDTO from '../Models/UserDTO';
import { store } from '../Stores/store';
import { DocumentFormValues } from '../Pages/Management/DocumentForm/DocumentFormValues';
import { Category } from '../Models/Category';
import { DocumentUpdateDTO } from '../Pages/Management/DocumentForm/DocumentUpdateDTO';
import { Review } from '../Models/Review';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.managementStore.token;
    if(token && config !== undefined) config!.headers!.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(async response => {
    return response;
}, (error : AxiosError) => {
const { data, status } = error.response!;
switch(status){
    case 400:
        if(typeof data === 'string'){
            toast.error(data);
        }

        if (axios.isAxiosError(data)) {
            
        }
        break;
    case 401:
        toast.error('Unauthorised');
        store.managementStore.setToken(null);
        break;
}
return Promise.reject(error);
})

const responseBody = <T> (response : AxiosResponse<T>) => response.data;

const requests = {
    get : <T> (url: string) => axios.get<T>(url).then(responseBody),
    post : <T> (url: string, body : {}) => axios.post<T>(url, body).then(responseBody),
    put : <T> (url: string, body : {}) => axios.put<T>(url, body).then(responseBody),
    delete : <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const newsAndEvents = {
    list: () => requests.get<any>(`/newsandevents`)
}

const news = {
    get: (id: number) => requests.get<News>(`/news/${id}`),
    create: (news: News) => requests.post<void>(`/news`, news),
    update: (news: News) => requests.put<void>(`/news`, news),
    delete: (id: number) => requests.delete<void>(`/news/${id}`)
}

const event = {
    list: () => requests.get<any>(`/events`),
    get: (id: number) => requests.get<Event>(`/events/${id}`),
    create: (event: Event) => requests.post<void>(`/events`, event),
    update: (event: Event) => requests.put<void>(`/events`, event),
    delete: (id: number) => requests.delete<void>(`/events/${id}`)
}

const management = {
    login: (loginDto: LoginDTO) => requests.post<UserDTO>('/account/login', loginDto),
    register: (registerDto: RegisterDTO) => requests.post<UserDTO>('/account/register', registerDto)
};

const poster = {
    setCurrentPoster: (id : string) => requests.post<Picture>(`/poster/${id}`, ""),
    getActivePoster: () => requests.get<Picture>(`/poster`),
    deactivatePoster: (id : string) => requests.post<Picture>(`/poster/deactivate/${id}`, "")
};

const picture = {
    getPictures: () => requests.get<Picture[]>('/picture'),
    uploadPicture: (file : Blob) => {
        let formData = new FormData();
        formData.append('file', file);
        return axios.post<Picture>('Picture', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    deletePicture: (id: string) => requests.delete<number>(`/picture/${id}`)
};

const document = {
    getDocuments: () => requests.get<DocumentDTO[]>('/documents'),
    uploadDocument: (file : Blob, documentFormValues: DocumentFormValues) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('title', documentFormValues.title);
        formData.append('categoryId', documentFormValues.categoryId);
        return axios.post<DocumentDTO>('/documents', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    updateDocument: (documentFormVals : DocumentUpdateDTO) => requests.put<DocumentDTO>(`/documents`, documentFormVals),
    deleteDocument: (id : string, documentTitle : string) => requests.delete<number>(`/documents/${id}`)
}

const category = {
    getCategoires: () => requests.get<Category[]>(`/category`),
    createCategory: (category : Category) => requests.post<Category>(`/category`, category),
    deleteCategory: (id : string) => requests.delete<number>(`/category/${id}`),
    updateCategory: (category : Category) => requests.put<Category>(`/category`, category)
}

const review = {
    getReviews: () => requests.get<Review[]>(`/reviews`),
}

const agent = {
    newsAndEvents,
    news,
    event,
    management,
    poster,
    picture,
    document,
    category,
    review
}

export default agent