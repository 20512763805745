import { Formik } from 'formik';
import React from 'react'
import RegisterDTO from '../../../Models/RegisterDTO';
import { useStore } from '../../../Stores/store';

export default function RegisterForm(){
    const { managementStore } = useStore();
    const { register } = managementStore;

    const formVals : RegisterDTO = {
        email : "",
        password: "",
        secretKey: ""
    }

    const handleSubmit = (values : any) => {
        register(values);
    }

    return (
        <Formik
            initialValues={formVals}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
            >
            {({handleSubmit, handleChange, values}) => (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>
                    <br />
                    <input type="text" name="email" id="email" value={values.email} onChange={handleChange} />
                    <br/>
                    <label htmlFor="password">Password</label>
                    <br />
                    <input type="password" id="password" name="password" value={values.password} onChange={handleChange} />
                    <br />
                    <label htmlFor="secretKey">Secret Key</label>
                    <br />
                    <input type="password" id="secretKey" name="secretKey" value={values.secretKey} onChange={handleChange} />
                    <br />
                    <input type="submit" value="Submit Event" />
                </form>
            
            )}
        </Formik>
    );
}