import { Box, Button, Modal, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../Stores/store';

export default observer(function ConfirmEventDeleteModal() {
    const { generalStore } = useStore();
    const { isNewsConfirmationModalOpen, setNewsConfirmationModalStatus, deleteNews, deleteNewsId } = generalStore; 

    return (
        <Modal
        open={isNewsConfirmationModalOpen}
        onClose={() => setNewsConfirmationModalStatus(false)}
        >
            <Box sx={{backgroundColor: "white", width:"90%", margin:"8em auto", borderRadius:"5px"}}>
                <Box sx={{padding: "1em" }}>
                    <Typography variant="h5">Confirm Delete</Typography>
                    <Typography sx={{color:"black", textAlign:"left"}}>Are you sure you want to delete this news</Typography>
                    <Button onClick={() => deleteNews(deleteNewsId)} variant="contained" color={"warning"} sx={{margin: "0.3em", marginLeft:"0px"}}>Delete</Button>
                    <Button color={"info"} onClick={() => setNewsConfirmationModalStatus(false)} sx={{margin: "0.3em"}} variant="contained">Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
});