import React, { ReactNode } from 'react';
import './SideBar.scss';

interface props {
    extended: boolean,
    title: string | undefined,
    children: ReactNode[],
}

export default function SideBar({ extended = false, title = undefined, children }: props) {
    const classes = extended ? "sideBar extended" : "sideBar";
    
    return (
        <main className={ classes }>
            <div className="sideBar_container">
                { title ? <h1>{title}</h1> : <></> }
                { children }
            </div>
        </main>
    )
}