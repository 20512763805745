import { makeAutoObservable, runInAction } from "mobx";
import { Category } from "../Models/Category";
import agent from "../api/agent";
import { toast } from "react-toastify";

export default class CategoryStore {

    categories : Category[] = [];
    selectedCategory: Category | undefined = undefined;
    haveCategories : boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    

    getCategories = async () => {
        try {
            const fetchedCategories = await agent.category.getCategoires();
            if(fetchedCategories && fetchedCategories.length > 0){
                runInAction(() => {
                    this.categories = fetchedCategories;
                    this.haveCategories = true;
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    createCategory = async (category : Category) => {
        if(category.name === "") {
            console.error("Category must have a name");
            return
        }

        try {

            const createdCategory = await agent.category.createCategory(category);
            if(createdCategory){
                runInAction(() => {
                    this.categories = [...this.categories, createdCategory];
                    this.clearSelectedCategory();
                    toast.success(`${category.name} added successfully`);
                });
            }
        } catch (err){
            console.log(err);
        }
    }

    deleteCategory = async(id : string) => {
        try {
            const result = await agent.category.deleteCategory(id);
            if(result && result > 0) {
                runInAction(() => {
                    this.categories = this.categories.filter(x => x.id !== parseInt(id));
                    this.clearSelectedCategory();
                    toast.success(`Category Id ${id} deleted successfully`);
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    updateCategory = async (category : Category) => {
        try {
            const updatedCategory = await agent.category.updateCategory(category);
            if(updatedCategory){
                this.categories = [...this.categories.filter(x => x.id !== updatedCategory.id), updatedCategory];
                this.clearSelectedCategory();
                toast.success(`${category.name} updated successfully`);
            }
        } catch (err) {
            console.log(err);
        }
    }

    setSelectedCategory = (id: string) => {
        this.selectedCategory = this.categories.find(x => x.id === parseInt(id));
    }

    clearSelectedCategory = () => {
        this.selectedCategory = undefined;
    }
}