import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function BriefHistory() {
    return (
        <SideBar title='A Brief History of the Towers' extended={true}>
            <Helmet>
                <title>Brief History | FOBT</title>
                <meta name="description" content="A outline on the history of Buckden Towers" />
                <link rel="canonical" href="/history/briefhistory" />
            </Helmet>
                <p>
                The site known as Buckden Towers has a rich history spanning over 900 years.   For much of that time, as Buckden Palace, it had been home to the Bishops of Lincoln.
                It is listed in the Domesday Survey of 1086 as a manor belonging to the Bishop of Lincoln, valued at just over £16, consisting of a church, a mill, a few cottages and a wood a mile square.<br/><br/>

                The current Church of St Hugh takes its name from the great Hugh of Avalon who was Bishop of Lincoln from 1186 to 1200.  St Hugh was a holy Bishop and a valiant statesman who was always
                prepared to make a stand even if his views brought him into conflict with King Henry II, his successor Richard I or the primate, Archbishop Hubert.<br/><br/>

                Another Hugh (Hugh de Wells) built a new house at Buckden in around 1225.   The previous structure had been of timber only.  This new house was intended to be a more permanent building and its
                development was continued by Robert Grosseteste, Bishop of Lincoln from 1235 to 1253, who was responsible for adding a Great Hall.  He was another outstanding Churchman of the Middle Ages.
                Although loyal, he did not hesitate to oppose both the King, Henry III, and the Pope, Innocent IV, when his conscience dictated.  He also tried, without success, to reconcile Henry and Simon de
                Montfort.<br/><br/>

                In 1291 a fire destroyed most of the buildings constructed by Hugh de Wells and Robert Grosseteste so that now only some stone foundations remain. <br/><br/>

                Construction of the Tower itself was completed by Bishop Rotherham in 1480 prior to his translation to the archbishopric of York.  However, Bishop John Russell (1480 - 1494) was responsible for the
                majority of the extensive rebuilding on the site.  The arms of Bishop Russell can be seen on the Inner Gatehouse (1480) and on the south gable front.  They comprise azure or, between three roses
                argent.  The new chapel was the work of Bishop William Smith (1495 - 1514) who was also one of the founders of Brasenose College, Oxford.

                Although the period from 1514 onwards was a busy one for The Towers, by 1595 Bishop Chaderton had decided that he could no longer afford to run the Palace and he bought a smaller estate at Southoe,
                south of Buckden.  His successor, however, returned to Buckden despite it having started to fall into disrepair.  More prosperous days for Buckden came during the episcopacy of John Williams
                (1625 - 1642).   The cloisters were repaired and refurbished and the stables and barns in the outer courtyard were rebuilt.  Improvements were also carried out to the grounds. <br/><br/>

                However, Williams who lived lavishly and became the holder of the title "Lord Keeper of the Great Seal" fell into disfavour with Charles I and Archbishop Laud over his conciliatory attitude towards
                the Puritans.   In 1637 he was accused of perjury, of revealing state secrets and of suborning false witness.  Fined £10,000 and deprived of his ecclesiastical revenues by the Star Chamber, he
                spent some time in the Tower of London.  Although Laud assumed jurisdiction of the See of Lincoln, he could not take away Williams' title of Bishop of Lincoln unless he resigned, which he refused
                to do.  A solicitor, Kilvert, was sent by the Star Chamber to administer the Buckden estate.  During the three years he lived there, Kilvert despoiled the Palace, sold the organ, books and pictures
                for ridiculously low prices and ruined the park by cutting down trees and killing deer. <br/><br/>

                In 1641 Bishop Williams was succeeded by Bishop Winniffe, who had the misfortune to have the Palace at Buckden and other appurtenances of his See taken from him by the Parliament during the Civil
                War.  In 1649, the property was sold for £8,174.82 to Alderman Sir Christopher Pecke,
                one time Lord Mayor of London. <br/><br/>

                With the restoration of the Monarchy in 1660, the Palace was returned to the See of Lincoln to which Bishop Sanderson was appointed in that year.  Although he only lived for three more years,
                Sanderson undertook the restoration the Palace with great speed, care and charge.  The Great Hall was not, however, restored to its former glory. <br/><br/>

                The Palace continued to be used by the Bishops of Lincoln; Bishop Barlow being known as the "Bishop of Buckden who never saw Lincoln".  The dean made efforts to induce him to show more interest in the
                Cathedral City, but Barlow remained loyal to Buckden, and when he died there in 1691 he was buried in the Parish Church. <br/><br/>

                The Eighteenth Century saw new methods in the techniques of road building; travel became easier and with its location on the Great North Road, the Palace became popular with visitors.  Bishop Thomas
                in 1750 received Count Zingandorf, the first Bishop of the Moravian Sect in England. <br/><br/>

                An idea of the state of the Palace can be gleaned from the diary of the Hon. John Byng, later Viscount Torrington, who visited Buckden in 1790 and stayed at the George:   "I had often try'd in vain to
                see the inside of the Bishop of Lincoln's Palace at Buckden and now unexpectedly succeeded; its appearance is castellated and within the walls certain strong turrets with apertures.  This ancient
                appearance diminishes hourly as much of the moat has been lately filled up and many walls pulled down.  The interior is grave, strong and useful; something to venerate; a good dining parlour, a neat
                chapel, tower stair cases and some stained glass in the windows." <br/><br/>

                In 1838, with the importance of the Palace diminished, the Ecclesiastical Commissioners decided that about half of the main buildings and part of the Gatehouse were to be demolished.  The materials
                and furnishings that were considered unnecessary were to be removed and sold, the profits being paid into the funds of the Commissioners at the Bank of England.  The materials from the demolished
                buildings were sold in February 1838.  The sale of furnishings took place in November 1838.  It lasted for three days and was attended by nearly 1,000 people. <br/><br/>

                In 1842 the Palace and Park were conveyed to the Vicar of Buckden.  Part of the main building was used as an elementary school for girls and infants.  In 1870, it appeared that the connection with the
                Church was to be severed since it was in that year that the property was sold to Mr James Marshall.  He proceeded to make the place habitable again and, at one stage, it appeared that he was going to
                restore and enlarge the old buildings, architectural plans for this purpose having been drawn up.   Eventually, he decided against this plan and even considered burning down the Great Tower. <br/><br/>
                </p>
            <hr/><br/>
            <h2><Link to="/history">Return</Link> </h2>
        </SideBar>
    )
}