import React, { useState } from 'react'
import 'react-dropdown/style.css';
import { NavBarLink } from './NavBarLink';
import { Link, NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

interface props {
    links : NavBarLink[]
}

export default function MobileNavBar({links} : props){

    const [isSideBarOpen, setIsSideBarOpen] = useState(false);

    const showSideBar = () => setIsSideBarOpen(!isSideBarOpen);

    return (
        <>
            <div className="navbar">
                <Link to='#' className="menu-bars" onClick={showSideBar}>
                    <FaBars color="white" style={{zIndex: "100"}} onClick={showSideBar}/>
                </Link>
            </div>

            <nav className={isSideBarOpen ? 'app__navbar active' : 'app__navbar'}>
                <ul className='app__navbar-links'>
                    <li className='nav-text' onClick={showSideBar}>
                        <Link to='#' className='app__navbar-links-toggle'>
                            <GrClose style={{zIndex: "2"}}/>
                        </Link>
                    </li>
                    {links.map((item, index) => (
                        <li key={`link-${index}`} onClick={showSideBar} className="nav-text">
                            <NavLink to={item.href}>{item.title}</NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    )
}