import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useStore } from '../../Stores/store';
import './Poster.scss';

export default observer(function Poster(){
    const { posterStore } = useStore();
    const { hasShownPoster, getCurrentPoster, currentPoster, isPosterActive, closePoster} = posterStore;


    const delay = (ms : number) => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    useEffect(() => {
        async function showPoster(){
            if(!hasShownPoster){
                await delay(5000);
    
                if(!currentPoster && !hasShownPoster){
                    getCurrentPoster(true);
                }
            }
        }
        
        showPoster();
    })

    if(currentPoster && isPosterActive === true)
    {
        return (
            <div className="activePoster" style={{zIndex:'120'}} onClick={() => closePoster(currentPoster?.id)}>
                <div className="posterContainer">
                    <button className="closeButton" onClick={() => closePoster(currentPoster?.id)}>Close</button>
                    <img src={currentPoster?.url} alt={currentPoster?.alt} style={{zIndex:'130'}}/>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{display: 'none'}}>
            </div>
        )
    }
   
});