import { createContext, useContext } from "react";
import GeneralStore from "./generalStore";
import ManagementStore from "./managementStore";
import PosterStore from "./posterStore";
import CategoryStore from "./categoryStore";
import ReviewStore from "./reviewsStore";

interface Store {
    generalStore: GeneralStore,
    managementStore: ManagementStore,
    posterStore: PosterStore,
    categoryStore: CategoryStore,
    reviewStore: ReviewStore
}

export const store: Store = {
    generalStore : new GeneralStore(),
    managementStore : new ManagementStore(),
    posterStore : new PosterStore(),
    categoryStore : new CategoryStore(),
    reviewStore : new ReviewStore()
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}