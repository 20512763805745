import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheGreatTower() {
    return (
        <SideBar title='The Great Tower' extended={false}>
            <Helmet>
                <title>The Great Tower | FOBT</title>
                <meta name="description" content="Information regarding the great tower restoration at The Buckden Towers" />
                <link rel="canonical" href="/abouttowers/thegreattower" />
            </Helmet>
            <p className="newsinfo1"> The foundations were laid about the year 1479 by Bishop Rotherham (1472-80), but this work ceased upon his death in 1480 and was not recommenced by his
                successor, Bishop Russell, until 1491. The lowest storey resting upon the vaulted arches of the great cellar, formed a large dining hall, the large apartment immediately
                above it being called the King's Lodging. The Great Tower also contained ten other rooms. The restoration of the tower was undertaken by the Claretian Missionaries in 1957
                and used as dormitories and play room for the junior seminary. The top picture on the right shows the state of the Tower prior to this work commencing. <br/><br/>

            The original restoration work did not last for very long and another major refurbishment project was required to replace the roof and bring the rest of thre building up to modern
            required standards. This work was completed in 1996 and the Tower has now four levels connected by two spiral staircases. The upper two levels have been converted into dormitories
            each level containing about twenty beds, rooms for group leaders, showers, wash basins and toilets.  The lowest level has been converted into a dining room and fully equipped
            kitchen and the remaining level has been converted into a large meeting room complete with period fireplace. The whole Tower is now centrally heated and has electric light and
            running hot and cold water.<hr/></p>
            <br/>

            <h2><Link to="/abouttowers"> Return</Link> </h2>
        </SideBar>
    )
}