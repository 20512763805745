import React from 'react'
import './Footer.scss';

export default function Footer() {
    return (
        <footer>
            <div id="footerContainer">
                <div id="addressContainer">         
                    <p className="footerHeader">Address</p>
                    <p className="footerFont">
                    The Friends of Buckden Towers<br></br>
                    The Towers <br></br>
                    High Street<br></br>
                    Buckden<br></br>
                    St Neots <br></br>
                    Cambridgeshire <br></br>
                    PE19 5TA</p>
                </div>
        
                <div id="toursContainer">
                    <p className="footerHeader">Tours</p>
                    <p className="footerFont">Tours of The Towers are available by prior arrangement.</p>  
                    <p className="footerFont">Tours operate from the 1st March to the 31st October</p>  
                    <p className="footerFont">Tours last approximately 1 hour and include The Knot Garden and a short walking tour of the exterior, and where possible,
                the interiors of some buildings.</p>
                    <p className="footerFont">As the Centre is a working Conference Centre, access to the interiors of buildings is not guaranteed.</p>
                    <p className="footerFont">If you are considering organising a Tour for a group, refreshments are available.</p>
                    <p className="footerFont">For further information, and to arrange a tour,  please e-mail your requirements to: <a href="mailto:admin@friendsofbuckdentowers.co.uk" className="contactEmail">admin@friendsofbuckdentowers.co.uk</a>
                    </p>
                </div>

                <div id="contactContainer">
                    <p className="footerHeader">Contact Email Address</p>
                    <a className="footerFont contactEmail" href="mailto:admin@friendsofbuckdentowers.co.uk">admin@friendsofbuckdentowers.co.uk</a>
                </div>
            </div>
        </footer>
    )
}
