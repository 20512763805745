import React from 'react'
import parser from 'html-react-parser';

interface Props {
    title: string,
    content: string
}

export default function News({ title, content }:Props) {
    return (
        <article>
            <h2 style={{marginBottom:"5px"}}>{title}</h2>
            <div style={{padding:"2% 5%"}}>
                {parser(content)}
                <hr/>
            </div>
        </article>
    )
}