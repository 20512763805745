import React from 'react'
import Picture from '../../../Models/Picture';
import { useStore } from '../../../Stores/store';
import PictureTile from './PictureTile/PictureTile';
import './PictureGallery.scss';
import { observer } from 'mobx-react-lite';

export default observer(function PictureGallery() { 
    const { managementStore } = useStore();
    const { galleryPictures } = managementStore;
    
    return (
        <div className="gallery">
            {
                galleryPictures.map((picture : Picture) => {
                    return <PictureTile key={`pic_${picture.id}`} alt={picture.alt} url={picture.url} id={picture.id} isActive={picture.isActive}/>
                })
            }
        </div>
    );

});