import React from 'react'
import './ResourceItem.scss';

interface Props {
    url:string,
    id: number,
    title: string,
    fileName: string
}

export default function ResourceItem({url, id, title, fileName}: Props){
    return (
            <div className="resourceItemContainer">
                <a href={url} key={`resource_${id.toString()}`} target='blank' rel="noreferrer">{title}</a>
                    <hr/>
            </div>
    )
}