import React from 'react'
import { useStore } from '../../Stores/store';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { NavBarLink } from './NavBarLink';

interface props {
    links : NavBarLink[]
}

export default observer(function DesktopNavBar({links} : props){
    const { posterStore } = useStore();
    const { currentPoster, setIsPosterActive, closePoster, isPosterActive } = posterStore;

    
    return (
        <nav>
            <ul onClick={() => {if(isPosterActive) {
                if(currentPoster){
                    closePoster(currentPoster?.id);
                }
            }}}>
                {links.map((link, index) => { return <li key={`${link.title}_${index}_item`}><NavLink style={{color: 'white'}} key={`${link.title}_${index}`} to={link.href}>{link.title}</NavLink></li>})}
                {
                    currentPoster ? <li className="featuredEvent" key="posterLink"><a style={{color: 'red'}} onClick={() => setIsPosterActive(true)}>Featured Event</a></li> : <></>
                }
            </ul>
        </nav>
    )
});