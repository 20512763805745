import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function Claretians() {
    return (
        <SideBar title='The Claretians' extended={true}>
          <Helmet>
            <title>Claretians | FOBT</title>
            <meta name="description" content="Some brief history regarding the Claretian Order" />
            <link rel="canonical" href="/history/claretians" />
          </Helmet>
  
        <p className="newsinfo1">
        St Anthony Mary Claret, founder of the Claretian Order. was born in Sallent in Catalonia, Spain, in 1807.  The son of a weaver, he took up weaving but then, desiring to become a Jesuit,
        started studying for the priesthood. However, ill health prevented his entering the Order and, having entered the Seminary in Vich, north of Barcelona on 29 September 1829, he was ordained
         as a secular priest on 13 June 1835 (the feast of St Anthony of Padua). Following a few years of pastoral experience, he volunteered to be sent as an Apostolic Missionary wherever the
         preaching of the Gospel was most needed. He preached, taught, organised and counselled throughout Spain, the Canary Islands and beyond and, not satisfied with all of this, he became a
         prolific author, writing some 144 books and pamphlets. <br/><br/>
  
        At a meeting on 16th July 1849 at the Seminary in Vich, he with the help of five other priests founded the congregation of "The Sons of the Heart of Mary" (Cordis Mariae Filii – CMF).
        Consecrated Bishop on 6 October 1850, he left for Cuba on a missionary assignment. While in Cuba he preached the word of God in all the Island’s villages, fought racial discrimination,
        established co-operatives and initiated many social projects. On 25 August 1855 he founded the Religious Sisters of Mary Immaculate (Claretian Missionary Sisters) to take care of teaching
        the catechism, education and other missionary activities. He worked for the spiritual and material betterment of all sectors of Cuban society and he was loved by many, but also hated and
         persecuted by some. He experienced 14 assassination attempts; the most serious being on 1 February 1856 when he was almost killed. <br/><br/>
  
        After seven years in Cuba he was called back to Spain by the Queen, Isabel II, to become her confessor and spiritual adviser. While accompanying her on visits to the different regions of
         Spain he worked hard for the good of the Church and people, with special concern for the Priests and Religious men and women. He also established centres for the formation of new evangelisers
          and even gathered intellectuals, artists, translators in what he called the ‘Academy of San Miguel’ where any lay person could co-operate in the task of the evangelisation. Due to the 1868
          Spanish revolution he was forced into exile to France. He took part in the First Vatican Council and was the first defender of Papal infallibility. Sick and persecuted by his enemies,
           he escaped to the Cistercian monastery of Fontfroide, France, where he died on 24 October 1870. <br/><br/>
  
        It is hard to believe that 50 years later there were nearly 1,500 Claretians in 6 countries. It has taken a further 100 years for this number to double to its present figure of nearly 3,000
        in 57 countries. <br/><br/>
  
        Despite its international coverage, the Congregation has not lost sight of its founder’s original watchword: "USE ALL POSSIBLE MEANS FOR THE SALVATION OF ALL." <br/><br/>
  
        Today, Claretian Missionaries preach the word of God by <br/>
    </p>
  
      <ul id="claret">
        <li id="claretli">working in home and foreign missions</li> <br/>
        <li id="claretli">preaching popular missions, spiritual exercises, retreats</li> <br/>
        <li id="claretli">building Basic Ecclesiastical Communities</li> <br/>
        <li id="claretli">running parishes</li> <br/>
        <li id="claretli">educating the youth in schools and seminaries</li> <br/>
        <li id="claretli">training lay leaders</li> <br/>
        <li id="claretli">conducting adult education classes</li> <br/>
        <li id="claretli">implementing social and development programs</li> <br/>
        <li id="claretli">training catechists</li> <br/>
        <li id="claretli">writing books and publishing Catholic magazines and pamphlets</li> <br/>
        <li id="claretli">communicating in the modern apostolate through Mass Media</li> <br/>
      </ul>
  
        <p className="newsinfo1">
        They are pragmatic in outlook and give priority to: "whatever is most urgent, timely and effective." However, wherever possible priority is given to evangelical work in non-Christian areas, in de-Christianised groups, to the poor, to the youth and to families.
  
        "Father, in this time of constant change in our world, help the Claretians to re-establish your presence and your peace." 
        </p>

        <hr/><br/> 
        <h2><Link to="/history"> Return</Link> </h2>
      </SideBar>
    )
}