import React from 'react'
import { useStore } from '../../../Stores/store';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Category } from '../../../Models/Category';

export default observer(function CategoryForm(){
    const { categoryStore } = useStore();
    const { updateCategory, selectedCategory, createCategory } = categoryStore;

    const formVals : Category = selectedCategory ?? {
        id: 0,
        name: "",
      }

    const handleSubmit = (values : any) => {
        if(values.id === undefined || values.id === 0){
            createCategory(values as Category)
        }
        else {
            updateCategory(values as Category)
        }
    }

    return (
        <div>
            <Formik
                initialValues={formVals}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
                >
                {({handleSubmit, handleChange, values}) => (
                <form onSubmit={handleSubmit}>
                    <div className="fieldsContainer">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" value={values.name} onChange={handleChange} />
                        <input type="hidden" id="id" name="id" value={values.id} />
                        <br />
                        <input type="submit" value="Upload" />
                    </div>
                </form>
                )} 
            </Formik>
        </div>
    )
});