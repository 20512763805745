import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function Parish() {
    return (
        <SideBar title='History of the Parish' extended={true}>
            <Helmet>
                <title>Parish | FOBT</title>
                <meta name="description" content="A brief history of the Parish" />
                <link rel="canonical" href="/history/parish" />
            </Helmet>
                <h1 id ="newstitle">History of the Parish</h1>

                <p className="newsinfo1">
                    This is not the place to describe the history of The Towers. However, the way in which The Towers came into the possession of the Claretians is. During the First World War the house was used as a
                    convalescent hospital. After the War, in 1919, it was sold to an eccentric historian from Durham, Dr Robert Holmes Edleston. Although he never lived there, he spent much time excavating and
                    reconstructing parts of the old Bishop's Palace. On his death The Towers was left to his sister who, having no use for the property, donated it to Bishop Leo Parker of the Catholic Diocese of
                    Northampton for charitable purposes. <br/><br/>

                    It was the personal friendship between Fr Arthur Crook cmf with Bishop Parker that led to the Bishop giving The Towers in 1956 to the Claretian Missionaries to be used as a junior seminary.
                    This was at the same time as Highcliffe Castle in Hampshire (now Dorset) was purchased by the Claretians for use as the Major Seminary in England. With falling numbers of seminarians, the Castle
                    was sold to a local businessman for redevelopment in 1966 and there then followed two disastrous fires. The Castle is now in the hands of Christchurch Council. <br/><br/>

                    Fr Lucian Olivares cmf was the first Superior of the community at The Towers and he, accompanied by Bro Luis Sanz cmf, lived in Huntingdon and walked each day the 5 miles to Buckden to prepare the
                    House for the new community. The main Tower, which had stood gutted and derelict for many years, had a roof and floors installed. In September 1957, 31 young boys came when the boarding school was
                    opened. The staff was made up of Claretian Missionaries - priests, brothers and students. Father Jim Fischer cmf came to Buckden for the first time, as Superior in 1958. The inner courtyard was
                    completed and a new chapel, simple and modern in style yet harmonising well with its surroundings, was built. It was dedicated to St Hugh of Lincoln by Bishop Parker of Northampton in 1959. <br/><br/>

                    A modern parish hall to the north of the Victorian House was built and named St Stephen's Hall after Fr. Stephen Emaldia, the Provincial Superior of the Claretians from 1950 to 1962. The Junior
                    Seminary closed in 1965, but not before Fathers Chris Newman and Michael Fewell, both to become Parish Priests in Buckden in later years, had passed through it as young students.
                    With the closure of the junior seminary a new role had to be found for The Towers. It played host to thousands of young people on residential courses - conditions were, however, rather spartan -
                    but that was all part of the attraction of staying in The Tower.  At that time, Buckden was part of St Neot’s Parish and it was not until 1969 that, as part of the newly formed Diocese of East
                    Anglia, it became a parish in its own right absorbing elements of both St Neots and Huntingdon parishes. Fr Michael Mahon cmf became the first Parish Priest. <br/><br/>

                    In 1974 the Claret Centre was opened for conferences and spiritual retreats. In 1988, an appeal was launched to restore and develop the whole complex. The Inner Gatehouse, the Great Tower and the
                    Victorian House were all to be the subject of restoration work. Completed at Easter in 1996, the Tower once again welcomed groups of young people (the first group coming from France). Supported by
                    the Friends of Buckden Towers, restoration work continues both to the buildings and in the grounds and activities within the Parish and at the Claret Centre continue to expand in both scope and
                    numbers. <br/><br/>

                    Following the decision to reduce the number of parishes within the Diocese of East Anglia, the Parish of St Hugh of Lincoln in Buckden and the Parish of St Joseph in St Neots were amalgamated into
                    one parish in September 2010. This reversed the situation prior to 1969 when Buckden was part of the parish of St Neots. Although the new parish remains in the Diocese of East Anglia, it is under
                    the care of the Claretian Missionaries based in Buckden. All the facilities at St Joseph's including the church, parish house and garden remain in active use.
                </p>

                <hr/><br/>
                <h2><Link to="/history">Return</Link></h2>
        </SideBar>
    )
}