import React from 'react'
import { Helmet } from 'react-helmet';
import GalleryImage from '../../Components/GalleryImage/GalleryImage'
import './Gallery.scss';

export default function Gallery() {
    return (
        <main>
            <Helmet>
                <title>Gallery | FOBT</title>
                <meta name="description" content="A collection of photographs illustrating the restoration of the historic buildings, and some of the interesting social activities enjoyed in the past" />
                <link rel="canonical" href="/gallery" />
            </Helmet>
            <h1 id="Galleryhead">Gallery</h1>

            <h2 id="Gallerytitle1">Old Pictures of the Towers <br/>(Hover over images for more information)</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="Old1.jpg"
                    hoverText="The Towers viewed from the West.  The moat, extending between the Tower and St Mary's Church, and the
                    causeway between the Inner and Outer Gatehouses can clearly be seen and the Palace can just be seen to the
                    left of the Tower."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="Old2.jpg"
                    hoverText="An old postcard showing The Towers viewed from the West. The moat, extending between the Tower and
                    St Mary's Church, and the causeway between the Inner and Outer Gatehouses can clearly be seen and the
                    Palace can just be seen to the left of the Tower."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="Old3.jpg"
                    hoverText="The Towers viewed from the East.  The picture was taken before the northern end of the Inner Gatehouse
                    was reconstructed with material from two 15th century houses by Dr Robert Holmes Edleston, who bought the
                    property in 1919."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">Aerial Images of the Towers</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="aerial1.jpg"
                    hoverText="The Towers viewed from the South West.  Note the formal garden behind the house still existed and the
                    Knot Garden had not yet been constructed (where the vegetable garden can be seen in the picture)."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="aerial2.jpg"
                    hoverText="The Towers viewed from the West.  The Knot Garden had not yet been constructed
                    (where the vegetable garden can be seen in the picture)."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="aerial3.jpg"
                    hoverText="An almost vertical view of the Towers taken circa 2001.  The Knot Garden can be clearly seen and the
                    marque had been erected for a wedding reception."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">The Historic Buildings</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="building1.jpg"
                    hoverText="A view of the Great Tower, Curtain Wall and Inner Gatehouse viewed from the Knot Garden
                    St Hugh's RC Parish Church can be seen to the left of the Tower and the Victorian House to the right of the
                    Inner Gatehouse"
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="building2.jpg"
                    hoverText="A view of the Inner Gatehouse viewed from the Knot Garden"
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="building3.jpg"
                    hoverText="The Inner Gatehouse viewed from the Car Park"
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">The Grounds and Knot Garden</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="knot1.jpg"
                    hoverText="Clearance of the area to be transformed in to the Knot Garden."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="knot2.jpg"
                    hoverText="Several knots have been planted and are starting to grow."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="knot3.jpg"
                    hoverText="Another view of the newly planted knots."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">Restoration, pre 2004</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="resto1.jpg"
                    hoverText="In this picture the restoration of the main part of the wall has been completed. However, some of the crenellations still have to be restored."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="resto2.jpg"
                    hoverText="Cement removal on the Outer Gatehouse."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="resto3.jpg"
                    hoverText="More crenellation work. Notice the different colours of the wall. The wall on the left of the picture is where work has been completed. That on the right of the picture has yet to be restored."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">Restoration, 2004/2005</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="restoration1.jpg"
                    hoverText="Materials and equipment stored under the archway in the Inner Gatehouse."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="restoration2.jpg"
                    hoverText="Note how the ceiling/floor above has been supported during repairs."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="restoration3.jpg"
                    hoverText="One of the larger lounges."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">The Old Gatehouse Shop</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="shop1.jpg"
                    hoverText="The exterior of the Shop.  Note the barrow from which produce grown by a local gardener was sold to boost the garden restoration fund."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="shop2.jpg"
                    hoverText="The shop interior looking back towards its entrance."
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="shop3.jpg"
                    hoverText="Another view of the inner part of the Shop."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">Events at the Towers</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="events1.jpg"
                    hoverText="King Henry VIII, wives and villagers pass through the Inner Gatehouse and into the Outer Ward"
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="events2.jpg"
                    hoverText="At last - we are ready!"
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="events3.jpg"
                    hoverText="One of the Produce stalls at the Heritage Day event."
                    />
                </li>
            </ul>

            <h2 id="Gallerytitle1">Plant Sale Day - May 2022</h2>
            <ul className="rig">
                <li>
                    <GalleryImage 
                    imagePath="plantsaleday1.jpg"
                    hoverText=""
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="plantsaleday2.jpg"
                    hoverText=""
                    />
                </li>
                <li>
                    <GalleryImage 
                    imagePath="plantsaleday3.jpg"
                    hoverText=""
                    />
                </li>
            </ul>


        </main>
    )
}