import { observer } from 'mobx-react-lite';
import React from 'react'
import Picture from '../../../Models/Picture';
import { useStore } from '../../../Stores/store';
import './PosterPreview.scss';


export default observer(function PosterPreview(){
    const { managementStore, posterStore } = useStore();
    const { isPreviewingPoster, previewPoster, closePosterPreview, deletePicture } = managementStore;
    const { setNewCurrentPoster, deactivatePoster } = posterStore;
    
    if(isPreviewingPoster !== undefined && isPreviewingPoster === true && previewPoster !== undefined)
    {
        const poster : Picture | undefined = previewPoster;

        return (
            <div className="posterPreview" style={{zIndex:'120'}} onClick={() => closePosterPreview()}>
                <div className="posterContainer">
                    <div className="buttonbar" style={{zIndex:'140'}}>
                        <button style={{backgroundColor:'green'}} onClick={(e) => {
                            e.stopPropagation();
                            setNewCurrentPoster(previewPoster.id);
                        }}>Set as Active</button>
                        <button style={{backgroundColor: 'yellow'}} onClick={(e) => {
                            e.stopPropagation();
                            deactivatePoster(previewPoster.id);
                        }}>
                            Deactivate
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            closePosterPreview();
                        }}>
                            Cancel
                        </button>
                        
                        <button style={{backgroundColor:'red'}} onClick={(e) => {
                            e.stopPropagation();
                            deletePicture(previewPoster.id);
                        }}>Delete Poster</button>
                        
                    </div>
                    <img src={poster?.url} alt={poster?.alt} style={{zIndex:'130'}} onClick={(e) => {e.stopPropagation()} }/>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{display: 'none'}}>
            </div>
        )
    }
   
});