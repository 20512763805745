import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheVictorianHouse() {
    return(     
        <SideBar title="The Victorian House" extended={true}>
            <Helmet>
                <title>The Victorian House | FOBT</title>
                <meta name="description" content="Information and history regarding the Victorian House at Buckden Towers" />
                <link rel="canonical" href="/abouttowers/thevictorianhouse" />
            </Helmet>
            <p className="newsinfo1"> The Victorian House which was built by Arthur Wellington Marshall in 1872. During the construction of the house the moat in front of the Inner
            Gatehouse was filled in. Arthur Marshall left The Towers in late 1910/early 1911 to reside in Folkestone which was considered a better place for his failing health.
            The house was offered to let in July 1911 in Country Life. Prior to its eventual sale, the House was used as a school and, during the Great War, it was used as a
            convalescent hospital. <br/><br/>

            Following the death of Marshall, the house was purchased at auction by Dr. R Holmes Edleston in July 1919. As a Fellow of the Society of Antiquaries, he was more interested
            in the ancient buildings, but he did keep the house in habitable condition and used it to host influential friends. During the Second World War, The Towers was used to house
            evacuees from Tollington School. <br/><br/>

            On the arrival of the Claretians in 1956, the House was the first building to be brought into use. Over the years that it was used as the Junior Seminary, rooms were converted
            to meet its requirements. In more recent years, as with many houses of this age, dry rot and woodworm have proved to be a recurring problem. In 1992 the House received a new
            roof, other essential repairs have been carried out and the house survives. <br/><br/>

            The most recent changes to the house occurred around Easter 2004 when the accommodation upstairs was modernised to provide additional bedrooms for visitors using the Claret
            Centre. There are now 15 bedrooms, most of which are twin-bedded but there are also several family rooms and two single rooms.<hr/></p><br/>

        <h2><Link to="/abouttowers"> Return</Link> </h2>
    </SideBar>
    )
}