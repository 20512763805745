import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useStore } from '../../Stores/store'
import EventForm from './EventForm/EventForm';
import EventTable from './EventForm/EventTable';
import NewsTable from './NewsForm/NewsTable';
import './Management.scss'
import NewsForm from './NewsForm/NewsForm';
import ConfirmEventDeleteModal from './EventForm/ConfirmEventDeleteModal';
import ConfirmNewsDeleteModal from './NewsForm/ConfirmNewsDeleteModal';
import LogInForm from './LogInForm/LogInForm';
import RegisterForm from './RegisterForm/RegisterForm';
import PictureGallery from './PictureForm/PictureGallery';
import PhotoUploadWidget from '../../Core/ImageUpload/PhotoUploadWidget';
import PosterPreview from './PosterPreview/PosterPreview';
import DocumentForm from './DocumentForm/DocumentForm';
import DocumentTable from './DocumentForm/DocumentTable';
import CategoryTable from './CategoryForm/CategoryTable';
import CategoryForm from './CategoryForm/CategoryForm';

export default observer(function Management() {
    const { generalStore, managementStore, categoryStore } = useStore();
    const { haveNewsAndEvents, getNewsAndEvents, updateHaveNewsAndEvents, setIsManagement } = generalStore;
    const { isRegistering, setIsRegistering, getPictures, haveGalleryPictures, haveDocuments, getDocuments } = managementStore;
    const { haveCategories, getCategories } = categoryStore;
    
    useEffect(() => {
        if(managementStore.token){
            setIsRegistering(false);
            setIsManagement(true);
            if(!haveNewsAndEvents){
                getNewsAndEvents();
                
                updateHaveNewsAndEvents(true);
            } 

            if(!haveGalleryPictures){
                getPictures();
            }

            if(!haveDocuments) {
                getDocuments();
            }

            if(!haveCategories){
                getCategories();
            }
        }
    }, [haveNewsAndEvents]);

    if(!managementStore.token) {
        return (
            <div className="loginContainer">
                <div className="loginWrapper">
                    {isRegistering === false ? <LogInForm /> : <RegisterForm />}
                    <button onClick={() => setIsRegistering(!isRegistering)}>{isRegistering ? "Back to Login" : "Register"}</button>
                </div>
            </div>
        )
    }

    return (
        <div >
            <Helmet>
                <title>Management</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <PosterPreview />
            <div className="paper">
                <div className="newsEventForm">
                    <h2>Event Management</h2>
                    <ConfirmEventDeleteModal />
                    <EventForm />
                    <EventTable />
                </div>
                <div className="newsEventForm">
                    <h2>News Management</h2>
                    <ConfirmNewsDeleteModal />
                    <NewsForm />
                    <NewsTable />
                </div>
            </div>

            <div className="paper">
                <div>
                    <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                        <h2>Poster</h2>
                        <PhotoUploadWidget />
                    </div>
                    <div className="managementPosterGallery">
                        <PictureGallery />
                    </div>
                </div>
            </div>

            <div className="paper" id="documentSection" >
                    <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column', width: '100%' }}>
                        <h2>Documents</h2>
                        <div className="formContainer">
                            <DocumentTable />
                            <DocumentForm />
                        </div>
                    </div>
            </div>

            <div className="paper" id="categorySection" >
                    <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column', width: '100%' }}>
                        <h2>Categories</h2>
                        <div className="formContainer">
                            <CategoryTable />
                            <CategoryForm />
                        </div>
                    </div>
            </div>
        </div>
    )
});