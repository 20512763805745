import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DocumentDTO } from "../Models/DocumentDTO";
import LoginDTO from "../Models/LoginDTO";
import Picture from "../Models/Picture";
import RegisterDTO from "../Models/RegisterDTO";
import UserDTO from "../Models/UserDTO";
import { DocumentFormValues } from "../Pages/Management/DocumentForm/DocumentFormValues";
import { toast } from "react-toastify";
import { CategoryParent } from "../Pages/Resources/CategoryParent";
import { DocumentUpdateDTO } from "../Pages/Management/DocumentForm/DocumentUpdateDTO";

export default class ManagementStore {
    token: string | null = window.localStorage.getItem('jwt');
    user : UserDTO | null = null;
    isRegistering : boolean = false;
    isAuthenticated : boolean = false;
    galleryPictures : Picture[] = [];
    selectedPictureId : string = ''; 
    pictureSelectionModalIsOpen : boolean = false;
    uploading: boolean = false;
    haveGalleryPictures: boolean = false;
    isPreviewingPoster : boolean = false;
    previewPoster : Picture | undefined = undefined;

    documents : DocumentDTO[] = [];
    selectedDocumentId : string = '';
    selectedDocument : DocumentFormValues | undefined = undefined;
    isPreviewingDocument = false;
    haveDocuments = false;
    documentToBeUploaded : Blob | undefined = undefined;
    documentToBeUploadedFileName : string = '';
    categoryParentList : CategoryParent[] = [];

    constructor(){
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if(token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        )
    }

    login = async (values : LoginDTO) => {
        try {
            if(values){
                const user = await agent.management.login(values);
                this.setToken(user.token);
                this.setUser(user);
            }
        }
        catch (ex){
            console.log(ex);
        }
    }

    register = async (values : RegisterDTO) => {
        try {
            if(values){
                const user = await agent.management.register(values);
                this.setToken(user.token);
                this.setUser(user);
            }
        }
        catch (ex){
            console.log(ex);
        }
    }

    getPictures = async() => {
        if(!this.haveGalleryPictures){
            try {
                const pictures = await agent.picture.getPictures();
                if(pictures){
                    this.setHaveGalleryPictures(true);
                    this.setPictures(pictures);
                }
            }
            catch (ex){
                console.log(ex);
            }
        }
    }

    uploadPicture = async(file : Blob) => {
        this.uploading = true;
        try {
            const response = await agent.picture.uploadPicture(file);
            const photo = response.data;
            runInAction(() => {
                if(photo){
                    this.galleryPictures.push(photo);
                }
                this.uploading = false;
            })
            
        }
        catch (ex) {
            console.log(ex);
            runInAction(() => {
                this.uploading = false;
            });
        }
    }

    deletePicture = async(id : string) => {
        try {
            const response : number = await agent.picture.deletePicture(id);
            //returns an int to determine success or failure;
            if(response > 0){
                runInAction(() => {
                    this.previewPoster = undefined;
                    this.isPreviewingPoster = false;
                    this.galleryPictures = [...this.galleryPictures.filter((picture) => {
                        return picture.id !== id;
                    })]
                })
            }
        }
        catch (ex) {
            console.log(ex);
            this.uploading = false;
        }
    }

    getDocuments = async() => {
        if(!this.haveDocuments){
            try {
                const docs = await agent.document.getDocuments();
                if(docs){
                    this.setHaveDocuments(true);
                    this.setDocuments(docs);
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
    };

    uploadDocument = async(file : Blob, documentFormValues : DocumentFormValues) => {
        this.uploading = true;
        try {
            const response = await agent.document.uploadDocument(file, documentFormValues);
            const document = response.data;
            runInAction(() => {
                if(document){
                    this.documents.push(document);
                }
                this.uploading = false;
                this.documentToBeUploaded = undefined;
                this.clearSelectedDocument();
                toast.success(`${documentFormValues.title} created successfully`);
            });
        }
        catch (ex) {
            console.log(ex);
            runInAction(() => {
                this.uploading = false;
            });
        }
    }

    deleteDocument = async(id : string, documentTitle : string) => {
        this.uploading = true;
        try {
            const response : number = await agent.document.deleteDocument(id, documentTitle);
            //returns an int to determine success or failure;
            if(response > 0){
                runInAction(() => {
                    this.documents = [...this.documents.filter((document) => {
                        return document.documentId !== id;
                    })];
                    this.uploading = false;
                    this.clearSelectedDocument();
                    toast.success(`${documentTitle} deleted successfully`);
                })
            }
        }
        catch (ex) {
            console.log(ex);
            this.uploading = false;
        }
    }

    updateDocument = async (documentFormValues : DocumentFormValues) => {
        try {
            const updateValues : DocumentUpdateDTO = {
                id: documentFormValues.id,
                title: documentFormValues.title,
                categoryId: documentFormValues.categoryId
            }
            const response : DocumentDTO = await agent.document.updateDocument(updateValues);
            if(response){
                runInAction(() => {
                    this.documents = [...this.documents.filter((document) => {
                        return document.documentId !== response.documentId;
                    }), response]
                    this.clearSelectedDocument();
                    toast.success(`${response.title} updated successfully`);
                })
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    setDocumentToUpload = (fileName: string, file : Blob) => {
        this.selectedDocument = undefined;
        this.documentToBeUploadedFileName = fileName;
        this.documentToBeUploaded = file;
    }

    setSelectedDocumentId = (documentId : string) => {
        const selectedDocumentDTO = this.documents.find(document => document.documentId === documentId);
        if(selectedDocumentDTO) {
            const selectedDocumentFormValues : DocumentFormValues = {
                id : selectedDocumentDTO.id,
                title : selectedDocumentDTO.title,
                file : selectedDocumentDTO.title,
                categoryId : selectedDocumentDTO.category?.id === undefined ? "" : selectedDocumentDTO.category?.id.toString()
            } 

            this.selectedDocument = selectedDocumentFormValues;
        }
    }

    clearSelectedDocument = () => {
        this.selectedDocument = undefined;
        this.selectedDocumentId = '';
    }

    buildCategoryParent = (documents : DocumentDTO[]) => {
        this.categoryParentList = documents.reduce((acc: CategoryParent[], cur: DocumentDTO) => {
        const categoryId = cur.category?.id;
        const categoryName : string | undefined = cur.category?.name;
        if (categoryId !== undefined && categoryId !== null) {
          const existingCategoryParent = acc.find((categoryParent) => categoryParent.id === categoryId);
          if (existingCategoryParent) {
            existingCategoryParent.documents.push(cur);
          } else {
            const newCategoryParent: CategoryParent = {
              id: categoryId,
              name: categoryName,
              documents: [cur],
            };
            acc.push(newCategoryParent);
          }
        }
        return acc;
      }, []);
    }

    setPictureConfirmationModalParameters = (isOpen : boolean, pictureId : string) => {
        this.setSelectedPictureId(pictureId);
        this.pictureSelectionModalIsOpen = isOpen;
    }

    closePictureSelectionModal = () => {
        this.pictureSelectionModalIsOpen = false;
    }

    setSelectedPictureId = (pictureId : string) => {
        this.previewPoster = this.galleryPictures.find(picture => picture.id === pictureId);
        if(this.previewPoster){
            this.isPreviewingPoster = true;
        }
    }

    closePosterPreview = () => {
        this.previewPoster = undefined;
        this.isPreviewingPoster = false;
    }

    setHaveGalleryPictures = (hasGalleryPictures : boolean) => {
        this.haveGalleryPictures = hasGalleryPictures;
    }

    setPictures = (pictures : Picture[]) => {
        this.galleryPictures = [...pictures];
    }

    setDocuments = (documents : DocumentDTO[]) => {
        this.documents = [...documents];
    }

    setHaveDocuments = (hasDocuments : boolean) => {
        this.haveDocuments = hasDocuments;
    }

    setUser = (user : UserDTO) => {
        this.user = user;
    }

    setToken = (token : string | null) => {
        this.token = token;
        if (token){
            this.setIsAuthenticated(true);
        }
        else {
            this.setIsAuthenticated(false);
        }
    } 

    setIsAuthenticated = (isAuthenticated : boolean) => {
        this.isAuthenticated = isAuthenticated;
    }

    setIsRegistering = (isRegistering : boolean) => {
        this.isRegistering = isRegistering;
    }
}