import React from 'react'
import { Helmet } from 'react-helmet'
import './AboutFOBT.scss'
import SideBar from '../../Components/SideBar/SideBar'

export default function AboutFOBT() {
    return (
        <SideBar title='About the Friends' extended={true}>
            <Helmet>
                <title>About FOBT | FOBT</title>
                <meta name="description" content="Describes the structure, objects, financial management and fund-raising activities of Friends of Buckden Towers" />
                <link rel="canonical" href="/aboutfobt" />
            </Helmet>
            <h2>Introduction</h2>
            <p> The “Friends of Buckden Towers” exists to support the owners of Buckden Towers in the care
            and maintenance of the property, and to promote the profile of The Towers as an interesting place to visit.
    
            The “Friends” are constituted as a Charitable Trust and Registered Charity, First Registered on 5th May 1977.
            Registered charity Number: 273480.
    
            The Trust’s Deeds may be viewed on request to the Chairman of The Council of Management at the address in the footer.
            
            A Council of Management composed of up to 12 members, some of whom are also Trustees, run the Charity.
            
            
            The Council raises funds, which are dispersed in the management and activities of The Council, and in the provision
            of grants towards the owners of Buckden Towers in connection with the purchase of furnishings and the maintenance of
            the property.
            
            Fund raising activities are through membership fees, grants, social activities, giving tours to the public, and
            through specialist events such as an Annual Plant Sale Day and Heritage Day.
            
            The Annual Report and Accounts for the last financial year may be viewed on request to the Chairman of The Council
            of Management at the address given above.</p>
    
            <hr />
    
            <h2 className="aboutSubTitle">Grants to the Owners</h2>
            <p className="newsinfo1">Grants made to the Owners over the last 5 years were:-</p>
    
                <table className='infoTable'>
                <tr>
                    <table>
                        <tbody>
                            <tr>
                                <th>Year</th>
                                <th>Amount</th>
                                <th style={{width: '100%'}}>In Respect of....</th>
                            </tr>
                        </tbody>
                    </table>
                </tr>
                
                <tr>
                    <table>
                        <tr>
                            <td>2019/2020</td>
                            <td>£2400</td>
                            <td>General Maintenance</td>
                        </tr>
                    </table>
                </tr>
                <tr>
                    <table>
                        <tr>
                            <td>2020/2021</td>
                            <td>£1600</td>
                            <td>General Maintenance</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£10000</td>
                            <td>Contribution towards the cost of remedial work on the Outer Gatehouse</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", paddingRight:"3px"}}>Total</td>
                            <td>£11600</td>
                            <td></td>
                        </tr>
                    </table>
                </tr>

                <tr>
                    <table>
                        <tr>
                            <td>2021/2022</td>
                            <td>£160</td>
                            <td>Knot Gardern maintenance</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£2975</td>
                            <td>Grants to Owners (Outer Gatehouse)</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£4246</td>
                            <td>Knot Garden Pavement</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", paddingRight:"3px"}}>Total</td>
                            <td>£7381</td>
                            <td></td>
                        </tr>
                    </table>
                </tr>
                <tr>
                    <table>
                        <tr>
                            <td>2022/2023</td>
                            <td>£6900</td>
                            <td>Path Project</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£1056</td>
                            <td>Friends Refectory repairs</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£100</td>
                            <td>Aerial Survey – Tower</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£500</td>
                            <td>Building Survey</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", paddingRight:"3px"}}>Total</td>
                            <td>£8556</td>
                            <td></td>
                        </tr>
                    </table>
                </tr>
                <tr>
                    <table>
                        <tr>
                            <td>2023/2024</td>
                            <td>£14276</td>
                            <td>Friends' Refectory repairs</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£5500</td>
                            <td>Outer Gatehouse Garden project</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>£880</td>
                            <td>Knot Garden Viewing Platform repair</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "right", paddingRight: "3px"}}>Total</td>
                            <td>£20656</td>
                            <td></td>
                        </tr>
                    </table>
                </tr>
            </table>

    
            <hr/>
    
            <h2 className="aboutSubTitle">The Mike Kiely Memorial Trophy</h2>
            <p className="newsinfo1"> A trophy was presented to The Friends by a Member to commemorate the memory of Michael Kiely, founder member of the Trust and a Council Member and Trustee.</p>
            <p className="newsinfo1">he trophy called the Michael Kiely Memorial Trophy is awarded annually at the AGM to a member of the Trust who has in the Council of Management’s opinion, made some exceptional contribution to the work of the Council.</p>
            <p className="newsinfo1">The Following are the recipients over the past few years:</p>
    
                <span id ="boldneed">The Following are the recipients over the past few years;</span>

            <table className="infoTable">
                <tr>
                    <th>Year</th>
                    <th>Name</th>
                    <th>Reason</th>
                </tr>
                <tr>
                    <td>2019/20</td>
                    <td>Pat Huff</td>
                    <td>Was Chairman of the Council for 4 years, contributing widely to the activities of FoBT and in particular to the organisation of the annual Plant Sale Days.</td>
                </tr>
                <tr>
                    <td>2020/21</td>
                    <td>Ann Petty</td>
                    <td>In recognition of her outstanding achievement in serving 20 years as Vice Chair of the Council of FoBT.</td>
                </tr>
                <tr>
                    <td>2021/22</td>
                    <td>Red McHugh</td>
                    <td>In recognition of his tireless commitment in supporting the activities of the Owners and the Friends of Buckden Towers</td>
                </tr>
                <tr>
                    <td>2023/24</td>
                    <td>Mike Allwright</td>
                    <td>In recognition of his outstanding and professional service over many years in carrying out the duties of Honorary Treasurer to the FoBT Council of Management.</td>
                </tr>
            </table>
      </SideBar>
    )
}