import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Event } from "../../../Models/Event";
import { useStore } from "../../../Stores/store";
import { ContentType } from "../../../Enumerators/ContentType";
import { Formik } from "formik";
import { observer } from 'mobx-react-lite';


export default observer(function EventForm() {

  const { generalStore } = useStore();
  const { selectedEvent, addUpdateEvent } = generalStore; 
  const apiKey: string | undefined = process.env.REACT_APP_TINY_MCE_KEY;

  const formVals : Event = selectedEvent ?? {
    id: 0,
    date: "",
    title: "",
    content: "",
    price: 0,
    type: ContentType.event
  }

  const handleSubmit = (values : any) => {
    addUpdateEvent(values);
  }

  return (
    <Formik
    initialValues={formVals}
    onSubmit={(values) => handleSubmit(values)}
    enableReinitialize
    >
    {({handleSubmit, handleChange, values}) => (
      <form onSubmit={handleSubmit}>
        <label htmlFor="title">Event Title</label>
        <br />
        <input type="text" name="title" id="title" value={values.title} onChange={handleChange} />
        <br/>
        <label htmlFor="date">Date</label>
        <br />
        <input type="datetime-local" name="date" id="date" value={values.date} onChange={handleChange} />
        <br/>
        <label htmlFor="content">Description</label>
        <Editor
          apiKey={apiKey}
          initialValue="<p>Event...</p>"
          init={{
            height: 300,
            menubar: false,
            plugins: [
              "advlist autolink lists link image",
              "charmap print preview anchor help",
              "searchreplace visualblocks code",
              "insertdatetime media table paste wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
          }}
          onChange={handleChange}
          onEditorChange={(e) => {
            handleChange({target: {name: "content", value: e}})
          }}
          value={values.content}
        />
        <br />
        <label htmlFor="price">Price</label> <br />
        <input type="number" id="price" name="price" value={values.price} onChange={handleChange}  />
        <br />
        <input type="hidden" id="id" name="id" value={values.id} />
        <br />
        <input type="submit" value="Submit Event" />
      </form>
    )} 
    </Formik>
  );
});
