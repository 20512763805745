import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function StHughesChurchAndChapels() {
    return (
        
    <SideBar title="St Hugh's Church and Chapels" extended={false}>
        <Helmet>
            <title>St Hughes Church and Chapels | FOBT</title>
            <meta name="description" content="Information regarding St Hughes Church and Chapels" />
            <link rel="canonical" href="/abouttowers/sthugheschurchandchapels" />
        </Helmet>
            <p className="newsinfo1">

            Located in the picturesque grounds of Buckden Towers, the Church of St Hugh of Lincoln is the parish Church of the local Catholic community. The Church was first blessed in October 1959 and has
            been continually busy with groups from inside and outside of the parish coming to use it. The large stained glass window at the back of the Church was installed at the time of building and
            depicts the life of Mary, Holy Mother of God. The window was originally placed in the Chapel of The Immaculate Heart of Mary, another Claretian parish Church in Hayes, Middlesex. The stained
            glass windows of St Hugh and St Anthony Claret, were commissioned in 1986 to celebrate the anniversary of St Hugh becoming Bishop of Lincoln. The artist was Paul Quail and other examples
            of his work can be seen in the remaining chapels at the Towers. <br/><br/>

            The Lady Chapel was built upon the foundations of the original Bishop’s Chapel in 1921 and contains more spectacular windows by Paul Quail. One commemorates Katherine of Aragon’s time at
            Buckden Palace and the others, which are situated behind the altar, illustrate the joyful, sorrowful, and glorious mysteries of the Rosary in a contemporary style. The Claretian Community
            uses this Chapel for morning and evening prayer and for weekday services. <br/><br/>

            Many visitors hold the St Claret Chapel in high regard. The Chapel itself is built from bare stone and wood in a simple under croft style. The uncluttered and simple style is designed to reflect
            the humble nature of the founder of the Claretian Missionaries. The far window, created by Paul Quail, adds to and enhances an overwhelming atmosphere of peace and tranquillity. The window,
                entitled The Resurrection, is another example of his more interpretive work and is used as a focus for contemplation and devotion. <hr/></p><br/>

            <h2><Link to="/abouttowers"> Return</Link></h2>
    </SideBar>
    )
}