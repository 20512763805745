import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/mainText.scss";
import App from "./Core/App";
import reportWebVitals from "./reportWebVitals";
import { store, StoreContext } from "./Stores/store";
import { BrowserRouter } from "react-router-dom";
import Poster from "./Pages/Poster/Poster";

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <BrowserRouter>
      <Poster />
      <App />
    </BrowserRouter>
  </StoreContext.Provider>,
  document.getElementById("root")
);

reportWebVitals();
