import { makeAutoObservable, runInAction } from "mobx";
import { News } from "../Models/News";
import { Event } from "../Models/Event";
import agent from "../api/agent";
import { ContentType } from "../Enumerators/ContentType";
import { toast } from 'react-toastify';

export default class GeneralStore {
  isAuthenticated: boolean = false;
  haveNewsAndEvents: boolean = false;
  isManagement: boolean = false;

  selectedEvent: Event | undefined = undefined;
  events: Event[] = [];
  isEventConfirmationModalOpen: boolean = false;
  deleteEventId: number = 0;

  selectedNews: News | undefined = undefined;
  news: News[] = [];
  isNewsConfirmationModalOpen: boolean = false;
  deleteNewsId: number = 0;
  isMobile = false;

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getNewsAndEvents = async () => {
    this.loading = true;
    try {
      let response: any = await agent.newsAndEvents.list();
      if (response !== null) {
        runInAction(() => {
            this.events = [];
            this.news = [];
          if(response.event){
            response.event.forEach((item: Event) => {
              let newEvent: Event = {
                id: item.id,
                date: item.date,
                title: item.title,
                content: item.content,
                price: item.price,
                type: ContentType.event,
              };
              this.events.push(newEvent);
            });
          }
         
          if(response.news){
            response.news.forEach((item: News) => {
              let newNews: News = {
                id: item.id,
                title: item.title,
                content: item.content,
                type: ContentType.news,
              };
              this.news.push(newNews);
            });
          }
          this.setLoading(false);
        });
      }
    } catch (ex) {
      if(ex){
        console.log(ex);
      }
      this.setLoading(false);
    }
  };

  getNews = async (id: number) => {
    this.loading = true;
    try {
      if (id) {
        let response = await agent.news.get(id);
        runInAction(() => {
          if (response !== null && isNews(response)) {
            this.selectedNews = response;
            this.setLoading(false);
          }
        });
      } else {
        console.log("No Id");
        this.setLoading(false);
      }
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  };

  getEvent = async (id: number) => {
    this.loading = true;
    try {
      if (id) {
        let response = await agent.event.get(id);
        runInAction(() => {
          if (response !== null && isEvent(response)) {
            this.selectedEvent = response;
            this.setLoading(false);
          }
        });
      } else {
        console.log("No Id");
        this.setLoading(false);
      }
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  };

  addUpdateNews = async (content: News) => {
    this.loading = true;
    try {
      if(content.id > 0){
        await agent.news.update(content);
        this.updateNews(content);
        this.clearSelectedNews();
        toast.success("News successfully updated");
      } else {
        await agent.news.create(content);
        runInAction(() => {
          this.clearSelectedNews();
          this.news.push(content);
          toast.success("News successfully added");
        })
      }
      this.setLoading(false);
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  }

  addUpdateEvent = async (content: Event) => {
    this.loading = true;
    try {
      if(content.id !== 0){
        await agent.event.update(content);
        this.updateEvents(content);
        this.clearSelectedEvent();
        toast.success("Event successfully updated");
      }
      else {
        await agent.event.create(content);
        runInAction(() => {
          this.clearSelectedEvent();
          this.events.push(content);
          toast.success("Event successfully added");
        })
      }
      this.setLoading(false);
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  }

  updateNewsOrEvent = async (content: News | Event) => {
    this.loading = true;
    try {
      if (isEvent(content)) {
        await agent.event.update(content);
        runInAction(() => {
          this.updateEvents(content);
          this.setLoading(false);
        });
      } else if (isNews(content)) {
        await agent.news.update(content);
        runInAction(() => {
          this.updateNews(content);
          this.setLoading(false);
        });
      }
    } catch (e) {
      console.log(e);
      this.setLoading(false);
    }
  };

  setLoading = (status: boolean) => {
    this.loading = status;
  };

  updateEvents = (event: Event) => {
    if (event.id) {
      this.events = [...this.events.filter((ev) => ev.id !== event.id), event];
    }
  };

  deleteEvent = async (id: number) => {
    this.loading = true;
    try {
      await agent.event.delete(id);
      this.removeEvent(id);
      this.setLoading(false);
      this.clearSelectedEvent();
      this.setEventConfirmationModalStatus(false);
    } catch (ex) {
      console.log(ex);
      this.setLoading(false);
    }
  };

  updateNews = (news: News) => {
    if (news.id) {
      this.news = [...this.news.filter((ne) => ne.id !== news.id), news];
    }
  };

  deleteNews = async (id: number) => {
    this.loading = true;
    try {
      await agent.news.delete(id);
      this.removeEvent(id);
      this.setLoading(false);
      this.clearSelectedNews();
      this.setNewsConfirmationModalStatus(false);
    } catch (ex) {
      console.log(ex);
      this.setLoading(false);
    }
  };
  
  removeEvent = (id: number) => {
    if (id) {
      this.events = this.events.filter((ev) => ev.id !== id);
    }
  }

  removeNews = (id: number) => {
    if (id) {
      this.news = this.news.filter((ne) => ne.id !== id);
    }
  }

  setSelectedEvent = (id: number) => {
    this.selectedEvent = this.events.find((x) =>  x.id === id);
  }

  clearSelectedEvent = () => {
    this.selectedEvent = undefined;
  }

  setSelectedNews = (id: number) => {
    this.selectedNews = this.news.find((x) =>  x.id === id);
  }
  
  clearSelectedNews = () => {
    this.selectedNews = undefined;
  }

  updateHaveNewsAndEvents = (trueOrFalse: boolean) => {
    this.haveNewsAndEvents = trueOrFalse;
  }

  setIsManagement = (trueOrFalse : boolean) => {
    this.isManagement = trueOrFalse;
  }

  setEventConfirmationModalStatus = (trueOrFalse: boolean, id? : number | undefined) => {
    if(id !== undefined) {
      this.deleteEventId = id;
    }
    this.isEventConfirmationModalOpen = trueOrFalse;
  }

  setNewsConfirmationModalStatus = (trueOrFalse: boolean, id? : number | undefined) => {
    if(id !== undefined){
      this.deleteNewsId = id;
    }
    this.isNewsConfirmationModalOpen = trueOrFalse;
  }

  setIsMobile = (windowSize : number) => {
    if(windowSize < 768){
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }
}


const isEvent = (content: Event | News): content is Event => {
  return (content as Event).type === ContentType.event;
};

const isNews = (content: Event | News): content is News => {
  return (content as News).type === ContentType.news;
};
