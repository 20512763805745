import { Delete, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react'
import { useStore } from '../../../Stores/store';
import { observer } from 'mobx-react-lite';


export default observer(function CategoryTable(){
    const { categoryStore } = useStore();
    const { categories, setSelectedCategory, deleteCategory } = categoryStore;

    let rows = [...categories];

    const columns: GridColDef[] = React.useMemo(() => [
        { field: 'id', headerName: '', hide: true},
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: "",
            headerName: "Actions",
            disableClickEventBubbling: true,
            renderCell: (params) => {
              const onClickEdit = () => {                
                setSelectedCategory(params.row.id);
              };

              const onClickDelete = () => {
                deleteCategory(params.row.id.toString());
              }
        
              return <div>
                <Button onClick={onClickEdit}><Edit /></Button>
                <Button onClick={onClickDelete}><Delete /></Button>
              </div>;
            },
            flex: 1
        }
        ], [categories]);

    return (
        <DataGrid rows={rows} columns={columns} autoHeight={true} />
    )
});