import React from 'react';
import { Helmet } from 'react-helmet';
import SideBar from '../../Components/SideBar/SideBar';

export default function Links() {
    return (
        <SideBar title='Links' extended={false}>
            <Helmet>
                <title>Links | FOBT</title>
                <meta name="description" content="A collection of useful links concerning the Buckden Towers" />
                <link rel="canonical" href="/links" />
            </Helmet>
            <p className="newsinfo1">

                <a href="http://www.buckden-towers.org.uk/" target="_blank" rel="noreferrer">Claret Centre, Buckden Towers</a> - Information
                about the Conference Centre at the Towers. <br/><br/>

                <a href="http://claret.org.uk/" target="_blank" rel="noreferrer">Claretian Missionaries Web Site</a> - A site containing much
                information on the work of the Claretian Missionaries (owners of the Towers) both within the UK and
                overseas.<br/><br/>

                <a href="http://www.genuki.org.uk/big/eng/HUN/Buckden/" target="_blank" rel="noreferrer">General Information on
                Buckden</a><br/><br/>

                <a href="https://www.gov.uk/government/organisations/charity-commission" target="_blank" rel="noreferrer">Web Site of the Charity
                Commission</a> - Information on the Rules for Registered Charities and a list of all Registered Charities in
                the UK.
            </p>
        </SideBar>

    )
}