import React, { Fragment } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home/Home';
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Footer/Footer';
import AboutFOBT from '../Pages/AboutFOBT/AboutFOBT';
import AboutTowers from '../Pages/AboutTowers/AboutTowers';
import History from '../Pages/History/History';
import NewsAndEvents from '../Pages/NewsAndEvents/NewsAndEvents';
import Gallery from '../Pages/Gallery/Gallery';
import Links from '../Pages/Links/Links';
import Parish from '../Pages/Parish/Parish';
import BriefHistory from '../Pages/BriefHistory/BriefHistory';
import Guide from '../Pages/Guide/Guide';
import Katherine from '../Pages/Katherine/Katherine';
import Claretians from '../Pages/Claretians/Claretians';
import TheGreatTower from '../Pages/TheGreatTower/TheGreatTower';
import TheInnerGateHouse from '../Pages/TheInnerGateHouse/TheInnerGateHouse';
import TheOuterGateHouse from '../Pages/TheOuterGateHouse/TheOuterGateHouse';
import TheVictorianHouse from '../Pages/TheVictorianHouse/TheVictorianHouse';
import TheGardenAndGround from '../Pages/TheGardenAndGrounds/TheGardenAndGrounds';
import TheQueenKatherineKnotGarden from '../Pages/TheQueenKatherineKnotGarden/TheQueenKatherineKnotGarden';
import StHughesChurchAndChapels from '../Pages/StHughesChurchAndChapels/StHughesChurchAndChapels';
import Management from '../Pages/Management/Management';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Resources from '../Pages/Resources/Resources';
import Membership from '../Pages/Memberships/Membership';
import Tours from '../Pages/Tours/Tours';

function App() {
  return (
    <Fragment>
      <NavBar />
      <ToastContainer position="bottom-left" />
      {/* <Poster /> */}
      <div id="displayPage">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutfobt' element={<AboutFOBT />} />
          <Route path='/abouttowers' element={<AboutTowers />} />
          <Route path='/history' element={<History />} />
          <Route path='/newsandevents' element={<NewsAndEvents />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/links' element={<Links />} />
          <Route path='/history/parish' element={<Parish />} />
          <Route path='/history/briefhistory' element={<BriefHistory />} />
          <Route path='/history/guide' element={<Guide />} />
          <Route path='/history/katherine' element={<Katherine />} />
          <Route path='/history/claretians' element={<Claretians />} />
          <Route path='/abouttowers/thegreattower' element={<TheGreatTower />} />
          <Route path='/abouttowers/theinnergatehouse' element={<TheInnerGateHouse />} />
          <Route path='/abouttowers/theoutergatehouse' element={<TheOuterGateHouse />} />
          <Route path='/abouttowers/thevictorianhouse' element={<TheVictorianHouse />} />
          <Route path='/abouttowers/thegardenandgrounds' element={<TheGardenAndGround />} />
          <Route path='/abouttowers/thequeenkatherineknotgarden' element={<TheQueenKatherineKnotGarden />} />
          <Route path='/abouttowers/sthugheschurchandchapels' element={<StHughesChurchAndChapels />} />
          <Route path='/management' element={<Management />} />
          <Route path="/documents" element={<Resources />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/tours" element={<Tours />} />
        </Routes>
      </div>
      <Footer />
    </Fragment>
  );
}

export default App;
