import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function TheQueenKatherineKnotGarden() {
    return (
        <SideBar title='The Queen Katherine Knot Garden' extended={true}>
            <Helmet>
                <title>The Queen Katherine Knot Garden | FOBT</title>
                <meta name="description" content="Information and history regarding the Queen Katherine Knot Garden at Buckden Towers" />
                <link rel="canonical" href="/abouttowers/thequeenkatherineknotgarden" />
            </Helmet>
            <p className="newsinfo1"> The walled garden at Buckden Towers, below the 15th century Great Tower, battlemented walls and inner Gatehouse of the historic old Palace of the Bishops
            of Lincoln, is dedicated to Katherine of Aragon, Queen of England, who was confined here by her husband, Henry VIII in 1533-34. <br/><br/>
            
            Begun in 1992, the garden is a replica of an enclosed Knot Garden typical of a small manor house of the Tudor period. It is intended as a place for peaceful reflection and
            remembrance as well as delight.<br/><br/>
            
            The main features of the garden are four square beds of Knots around a central fountain, which is covered in winter by a mosaic with a pomegranate design, together with two
            smaller Knots in front of the raised stone terrace. There is a tunnel arbour along two sides leading to a raised Mound and Viewing Platform, from which the Knot patterns, the
            buildings of the Tudor Palace and the Parish Church can be viewed to advantage.<br/><br/>
            
            Two of the main Knot beds are planted to contemporary designs of the 16th century. A third, with the words “Ecce Agnus Dei” (“Behold the Lamb of God”) surrounding a topiary
            lamb, replicates the design of the wooden boss in the bar of the Lion Hotel, formerly the Palace Hostel.<br/><br/>
            
            The fourth has been laid to a design from the Villa Medici in Florence.<br/><br/>
            
            The two smaller beds are planted to heraldic designs representing the arms of Bishop John Russell, which appear on the Gatehouse, completed by him in about 1490, and the
            Fleur de Lys, the emblem of the Bishops and City of London.<br/><br/>
            
            In 2012 the woodwork of the Tunnel Arbour was completely renewed, and in 2016 the Viewing Platform on top of the Mound was also rebuilt. Both projects were generously
            funded and executed by the Friends of Buckden Towers.<br/><br/>
            
            The arbour is planted with climbing plants such as hops, vines, honeysuckle, jasmine and clematis. Behind the arbour are beds of old roses such as the York and Lancaster
            roses, Rosa Rubiginosa, and there is a row of Almond trees along the daisy bank. There is a fine bush of the native Sweet Briar Rose, Rosa rubiginosa, known to Shakespeare as
            Eglantine and well-loved for the fragrance of its pink flowers and apple-scented foliage.<br/><br/>
            
            All the plants used in the garden are, as far as possible, contemporary to the period. Plants used in the knots and other beds include Box, Santolina, Sage, Germander, Rue,
            Lavender and Golden Marjoram. Pots of Pomegranate (Queen Katherine’s emblem), adorn two of the beds, and the pots on the stone terrace hold Box and Bay.<br/><br/>
            
            One of the beds on the upper terrace is now devoted to a collection of Dye plants while the other bed contains culinary and medicinal herbs. In 2006 a Judas Tree was planted
            to mark fifty years of Claretian residence at Buckden Towers.<br/><br/>
            
            Queen Katherine’s Garden, designed by William Dawson, has been financed and constructed entirely by donations and contributions from local people and businesses, and continues
            to be maintained by volunteers.<hr/></p><br/>
    
        <h2><Link to="/abouttowers">Return</Link> </h2>
    </SideBar>  
    )
}