import React, { useEffect } from 'react';
import { useStore } from '../../../Stores/store';
import Slider from "react-slick";
import ReviewItem from './ReviewItem';
import { observer } from 'mobx-react-lite';

export default observer(function ReviewGallery() {
    const { reviewStore } = useStore();
    const { getReviews, reviews, loading, itemsToShow } = reviewStore;

    const settings : object = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: itemsToShow,
        nextArrow: <></>,
        prevArrow: <></>,
        style: { width: '100%', margin: '0px 0px 15px 0px' },
      };

    useEffect(() => {
        if(!loading) {
           getReviews();
        }
    }, [getReviews]);

    if(loading) {
        return <div></div>
    } else {
        return (
            <Slider {...settings}>
                {reviews.map(review => <ReviewItem title={review.title} description={review.description} key={`review_${review.id}`} /> )}
            </Slider>
        )
    }
});