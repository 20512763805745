import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStore } from '../../Stores/store'
import { observer } from 'mobx-react-lite';
import ResourceItem from './ResourceItem';
import SideBar from '../../Components/SideBar/SideBar';

export default observer(function Resources(){
    const { managementStore, categoryStore } = useStore();
    const { documents, getDocuments, buildCategoryParent, categoryParentList } = managementStore;
    const { categories } = categoryStore;

    const [documentLoading, setDocumentLoading] = useState(true);

    useEffect(() => {
        const fetchDocs = async () => {
            await getDocuments();
            buildCategoryParent(documents);
        }
        fetchDocs();
        setDocumentLoading(false);
    },[documents, getDocuments, categories]);

    return (
        <SideBar title='Documents' extended={false}>
             <Helmet>
                <title>Documents | FOBT</title>
                <meta name="description" content="Downloadable resources relating to the Friends of Buckden Towers" />
                <link rel="canonical" href="/resources" />
            </Helmet>
            <div>
            { documentLoading ? 
                <div>Loading Documents</div> : 
                ( categoryParentList.map(category => {
                    const categorySection = 
                    <div>
                        <h3 key={`categoryHeader_${category.id}`}>{category.name}</h3>
                        {category.documents.map((document) => {
                            return <ResourceItem 
                                        id={document.id} 
                                        title={document.title} 
                                        fileName={document.fileName} 
                                        url={document.url}
                                        />
                        })}
                    </div>
                    return categorySection;
                })
                )
            }
            </div>
        </SideBar>
    )
});