import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Review } from "../Models/Review";

export default class ReviewStore {

    reviews : Review[] = [];
    loading : boolean = false;
    itemsToShow : number = 1;

    constructor() {
        makeAutoObservable(this);
    }

    getReviews = async () => {
        this.loading = true;
        try {
            const fetchedReviews = await agent.review.getReviews();
            if(fetchedReviews && fetchedReviews.length > 0){
                runInAction(() => {
                    this.reviews = fetchedReviews;
                    this.loading = false;
                    this.calculateItemsToShow();
                });
            }
        } catch (err) {
            runInAction(() => {
                this.loading = false;
                console.log(err);
            });
        }
    }

    calculateItemsToShow = () => {
        if(!this.reviews) {
            this.itemsToShow = 1;
        }

        if(window.innerWidth < 600) {
            this.itemsToShow = 1;
            return;
        }

        this.itemsToShow = this.reviews.length < 2 ? this.reviews.length : 2;
    }
}