import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SideBar from '../../Components/SideBar/SideBar'

export default function Katherine() {
    return (
        <SideBar title='Katherine of Aragon' extended={true}>
            <Helmet>
                <title>Katherine | FOBT</title>
                <meta name="description" content="A summary on the history of Katherine of Aragon" />
                <link rel="canonical" href="/history/katherine" />
            </Helmet>
            <p className="newsinfo1">
            Probably the most important Royal visitor to Buckden Palace was Katherine of Aragon. She was sent here by order of Henry VIII after the annulment of their marriage by Archbishop Cranmer.
            She resided in the Palace from July 1533 until 1534, occupying a room from which she had a direct view into the chapel. The ordinary people of England had a deep sympathy with Katherine and
            she was very popular with the villagers of Buckden. This, together with the fact that she was visited here by two Friars Observant who were her devoted supporters, made Henry determined to
            move her to a place where she could be more easily confined. In December 1533 Henry sent the Duke of Suffolk to force the Queen’s attendants to address her as "Princess Dowager" instead of
            treating her as Queen, and to remove Katherine herself either to Fotheringhay or Somersham. Suffolk arrived on 18th December, but he met with a blank reception. The Queen’s attendants, both
            English and Spanish, remained perfectly loyal to her and Katherine herself refused to be moved. Fotheringhay she had always disliked, and Somersham was so damp that it would probably cause
            her death. Suffolk lost his temper and shouted at her. Katherine retired to her room and sealed the door against him. <br/><br/>
    
            The men of Buckden now took a hand and showed their sympathy with the Queen. They gathered in the street outside silent but carrying choppers or billhooks and looking so threatening,
            that Suffolk’s courage began to fail him. He found himself, moreover, shunned by the gentry of the district. After several days he succumbed. All he could do was to remove the furnishings
            of the Palace that Katherine had brought with her, and have them packed up, ready to send to London. He arrested some of the Queen’s English attendants. Katherine herself remained behind
            the locked doors, saying that she could only be removed by force. This Suffolk, in view of the menacing crowds outside, dared not do, and he returned to London, defeated by the Queen and the
            men of Buckden. In the following May, Katherine was moved to more comfortable, but more easily protected quarters at Kimbolton where she lived the life of a semi-recluse until her death on 7
            January 1536 [not July as quoted in the original history]. <br/><br/>
    
            As you enter the Lady Chapel from the Main Church, the stained glass window depicting scenes from Queen Katherine's life can be found on the right hand side of the Chapel. <br/><br/>
            </p>
            <hr/><br/>  
            <h2 id="eventstitle"><Link to="/history"> Return</Link> </h2>
      </SideBar>
    )
}